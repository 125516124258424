import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import qs from "query-string";
import { Button } from "react-bootstrap";
import nearFar from "../assets/img/nearfar.jpg";
import "./styles.css";
import { getQuery } from "../utils";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from 'react-i18next';
import AudioGuide from '../components/AudioGuide'

function Mpage14Test1B(props) {

    const { t, i18n } = useTranslation();

	useEffect(() => {
		fetch(
			URL_ROUTE_USER +
				"/updateStatus" +
				window.location.search +
				"&pageStatus=4"
		)
			.then((response) => response.json())
			.then((data) => {
				console.log("updated");
				console.log(data);
			});
		localStorage.setItem("init_mobile", "y");
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const query = qs.parse(window.location.search);

	const Button1 = (val) => {
		let query = getQuery();

		if (val == 1) {
			localStorage.setItem("Answer1b", "near");
		} else if (val == 2) {
			localStorage.setItem("Answer1b", "far");
		} else {
			localStorage.setItem("Answer1b", "not sure");
		}

		console.log(
			URL_ROUTE_USER + "/sendAnswer" + window.location.search + `&answer=${val}`
		);

		fetch(
			URL_ROUTE_USER + "/sendAnswer" + window.location.search + `&answer=${val}`
		)
			.then((response) => response.json())
			.then((data) => {
				console.log("resp sedAnswer -->> ", data);
				if (data.message == "accepted") {
					const searchString = qs.stringify(query);
					props.history.replace({
						pathname: "/mtests",
						search: searchString,
					});
					props.history.push({
						pathname: "/mtests",
						search: searchString,
					});
					console.log("redirect mtests -->> ");
					fetch(
						URL_ROUTE_USER +
							"/updateStatus" +
							window.location.search +
							"&pageStatus=5"
					)
						.then((response) => response.json())
						.then((data) => {
							console.log("updated teste 1b");
							console.log("resp updateStatus ---->> ", data);
						});
				}
				// fetch(URL_ROUTE_USER + '/sendAnswer' +
				//     window.location.search + '&testStep=3' + `&answer=${0}`)
				//     .then(response => response.json())
				//     .then(data => {
				//         console.log(data);
				//         // console.log(data);
				//         // const searchString = qs.stringify(query);
				//         // props.history.push({
				//         //     pathname: '/mtest2',
				//         //     search: searchString
				//         // });
				//     });
			});
	};

	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("On which device is the picture easier for you to see?")}</h1>
					<div className="image-view">
						<img src={nearFar} alt="" style={{ width: "90%" }} />
					</div>
					<div className="nav-buttons">
						<div className="nav-button" onClick={() => Button1(1)}>
							{t("Mobile")}
						</div>
						<div className="nav-button" onClick={() => Button1(2)}>
							{t("Computer")}
						</div>
						<div style={{marginTop: "20px" }}>
							<AudioGuide 
								lang={query.lang}
								sound="mpage14test1b"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(Mpage14Test1B);
