import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qr-svg";
import axios from "axios";
import "./styles.css";
import {
	PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK,
	PAGE_STATUS_WAITING_FOR_CONNECTION,
	POLLING_INTERVAL,
	ROUTES_PC_CONNECTION,
	ROUTES_PC_STARTED_SESSION,
	URL_ROUTE_USER,
	QR_DOMAIN,
} from "../utils/constants";
import { useTranslation } from "react-i18next";

const QR_BACKGROUND_COLOR = "#FFF";
const QR_FOREGROUND_COLOR = "#000";

function Page11QR({ history }) {
	const [state, setState] = useState({ link: "" });
    const { t, i18n } = useTranslation();

	useEffect(() => {
		axios.get(URL_ROUTE_USER + "/create" + window.location.search, {
			withCredentials: true,
		})
			.then((resp) => {
				setState({ link: QR_DOMAIN + "/mconnection?token=" + resp.data + "&lang=" + i18n.language });
				pollingStatus(resp.data);
			});
	}, []);

	const pollingStatus = (token) => {
			axios.get(URL_ROUTE_USER + "/getAnswer?token=" + token + "&cover=0")
		// fetch(URL_ROUTE_USER + "/getAnswer?token=" + token + "&cover=0")
			// .then((response) => response.json())
			.then((res) => {
				const { pageStatus } = res.data;
				if (
					pageStatus === PAGE_STATUS_WAITING_FOR_CONNECTION &&
					window.location.pathname === ROUTES_PC_CONNECTION
				) {
					setTimeout(() => {
						pollingStatus(token);
					}, POLLING_INTERVAL);
				} else if (pageStatus === PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK) {
					history.push({
						pathname: ROUTES_PC_STARTED_SESSION,
						search: "?token=" + token,
					});
				}
			}).catch((error) => { 
				console.log('pollingStatus', error);
				// this.pollingStatus(token);
			});;
	};

	const questionText =
		t("Scan QR or enter the link below on your mobile device to continue");
	return (
		<div className="qrcode-view">
			<h2>{questionText}</h2>
			<div className="qr-code-wrapper">
				<QRCode
					bgColor={QR_BACKGROUND_COLOR}
					fgColor={QR_FOREGROUND_COLOR}
					level="Q"
					style={{ width: 450 }}
					value={state.link}
				/>
				<a href={state.link}>{state.link}</a>
			</div>
		</div>
	);
}

// class Page11QR extends React.Component {
// 	state = {
// 		link: "",
// 	};

// 	//todo: should create user profile in previous page to get consistent token for refreshing.
// 	componentWillMount() {
// 		console.log(URL_ROUTE_USER);
// 	}

// 	render() {
// 		return;
// 	}
// }

export default withRouter(Page11QR);
