export function axis_button(p5) {

  let i = 0;
  let r = 0;
  let w = 100;
  let stroker = 150;
  let strokeg = 150;
  let strokeb = 100;
  let strokea = 180;

  p5.setup = () => {p5.createCanvas(w, w);p5.rectMode(p5.CENTER);p5.angleMode(p5.DEGREES);}

  p5.updateWithProps = props => {
    if (props.r || props.i) {
      r = props.r;
      i = props.i;
    }
    console.log(i)
  };

  p5.draw = () => {

    // p5.noStroke()
    p5.strokeWeight(2)
    p5.stroke(0,0,0,strokea)
    p5.noSmooth()
    p5.background(255);
    p5.translate(w/2, w/2) 

    p5.rotate(i)

    // p5.fill(0,255,0)
    // p5.fill(2, 143, 30) // Dark
    p5.fill(255)
    p5.rect(0,0,r,r)
    // p5.strokeWeight(1)
    // p5.stroke(stroker,strokeg,strokeb,strokea)
    // p5.fill(132, 0, 0)
    // p5.fill(220,0,0)
    // p5.rect(0,0-r/10,r,r/10) // Dark
    p5.fill(0)
    p5.rect(0,0-r/5,r,r/5)  
    // p5.rect(0,0-5*r/10,r,r/10)
    p5.rect(0,0+r/5,r,r/5)
    // p5.rect(0,0+3*r/10,r,r/10) 
  
  }

}
