export default function TestWrapper({children, image}) {
   return (
      <main className="test-wrapper">
            <section className="wrapper">
                  {children}
            </section>
            <div className="image">
               <img src={image} alt="" />
            </div>
      </main>
   )
}