import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function TestView({ data }) {
	const { t } = useTranslation();

	if (!data) return null;
	let Ptdata = data;

	let Message1 = "Right Eye";
	let Message2 =
		"FINAL RX SPHERE: " +
		Ptdata.FinalSphOD +
		", CYLINDER: " +
		Ptdata.FinalCylOD +
		", AXIS: " +
		Ptdata.FinalAxisOD;
	let Message2b =
		"MSRD RX SPHERE: " +
		Ptdata.MeasuredSphOD +
		", CYLINDER: " +
		Ptdata.MeasuredCylOD +
		", AXIS: " +
		Ptdata.MeasuredAxisOD +
		" VAsc: " +
		Ptdata.VAscOD +
		"(" +
		(Math.round(Ptdata.SPEscOD * 4) / 4).toFixed(2) +
		")" +
		" VAa: " +
		Ptdata.VAaOD;
	let Message2c =
		" OLD RX SPHERE:  " +
		Ptdata.OldSphOD +
		", CYLINDER: " +
		Ptdata.OldCylOD +
		", AXIS: " +
		Ptdata.OldAxisOD +
		" VAcc: 20/" +
		Ptdata.VAccOD +
		"(" +
		(Number(Ptdata.OldSphOD) + Ptdata.OldCylOD / 2) +
		")";

	let Message3 = "Left Eye";
	let Message4 =
		"FINAL RX SPHERE:  " +
		Ptdata.FinalSphOS +
		", CYLINDER: " +
		Ptdata.FinalCylOS +
		", AXIS: " +
		Ptdata.FinalAxisOS;
	let Message4b =
		"MSRD RX SPHERE: " +
		Ptdata.MeasuredSphOS +
		", CYLINDER: " +
		Ptdata.MeasuredCylOS +
		", AXIS: " +
		Ptdata.MeasuredAxisOS +
		" VAsc: " +
		Ptdata.VAscOS +
		"(" +
		(Math.round(Ptdata.SPEscOS * 4) / 4).toFixed(2) +
		")" +
		" VAa: " +
		Ptdata.VAaOS;
	let Message4c =
		" OLD RX SPHERE:  " +
		Ptdata.OldSphOS +
		", CYLINDER: " +
		Ptdata.OldCylOS +
		", AXIS: " +
		Ptdata.OldAxisOS +
		" VAcc: 20/" +
		Ptdata.VAccOS +
		"(" +
		(Number(Ptdata.OldSphOS) + Ptdata.OldCylOS / 2) +
		")";

	let Message4a = "Add Power: +" + Ptdata.Add;

	let Message5 = "Contacts:";
	let Message6 =
		Ptdata.FinalSphOD +
		t("CYL") +
		": " +
		Ptdata.FinalCylOD +
		" " +
		t("AXIS") +
		": " +
		Ptdata.FinalCLAxisOD +
		" " +
		t("BRAND") +
		": " +
		Ptdata.CL_OldBrandOD +
		" " +
		t("BC") +
		": " +
		Ptdata.CL_OldBaseCurveOD +
		" " +
		t(" DIAM") +
		": " +
		Ptdata.CL_OldDiamOD;
	let Message7 =
		Ptdata.FinalSphOS +
		t("CYL") +
		": " +
		Ptdata.FinalCylOS +
		" " +
		t("AXIS") +
		": " +
		Ptdata.FinalCLAxisOS +
		" " +
		t("BRAND") +
		": " +
		Ptdata.CL_OldBrandOS +
		" " +
		t("BC") +
		": " +
		Ptdata.CL_OldBaseCurveOS +
		" " +
		t("DIAM") +
		":" +
		Ptdata.CL_OldDiamOS;

	return (
		<div className="thank-you-page test-view">
			<div className="thank-you-page-wrapper">
				<div className="paragraph1">
					<div className="user-info">
						<span>
							Name: <br />
							<strong>{Ptdata.name}</strong>
						</span>
						<span>
							E-mail: <br />
							<strong>{Ptdata.email}</strong>
						</span>
						<span>
							Gender: <br />
							<strong>{Ptdata.gender}</strong>
						</span>
						<span>
							Taken at: <br />
							<strong>
								{" "}
								{dayjs(Ptdata.createdAt).format("MM/DD/YYYY - HH:mm:ss")}
							</strong>
						</span>
					</div>

					<h5>{t("This page is for demonstration purposes only.")}</h5>
					<h1>{t("Glasses:")}</h1>
					<h2>{t("Right Eye")}</h2>
					<p>
						{t("FINAL RX SPHERE")}:{Ptdata.FinalSphOD}, {` `}
						{t("CYLINDER")}: {Ptdata.FinalCylOD}, {` `}
						{t("AXIS")}: {Ptdata.FinalAxisOD}
					</p>
					<p>
						{t("Add Power")}: +{Ptdata.Add}
					</p>

					<hr />

					<h2>{t("Left Eye")}</h2>
					<p>
						{t("FINAL RX SPHERE")}:{Ptdata.FinalSphOS}, {` `}
						{t("CYLINDER")}: {Ptdata.FinalCylOS}, {` `}
						{t("AXIS")}: {Ptdata.FinalAxisOS}
					</p>
					<p>
						{t("Add Power")}: +{Ptdata.Add}
					</p>
					<hr />

					<h1>{t("Contacts")}</h1>
					<h2>{t("Right Eye")}</h2>
					<p>
						{`${Ptdata.FinalSphOD}, `}
						{t("CYL")} {`${Ptdata.FinalCylOD}, `}
						{t("AXIS")} {`${Ptdata.FinalCLAxisOD}`}
						{Ptdata?.CL_OldBaseCurveOD
							? `, ${t("BC")}: ${Ptdata.CL_OldBaseCurveOD}`
							: null}
						{Ptdata?.CL_OldDiamOD
							? `, ${t("DIAM")}: ${Ptdata.CL_OldDiamOD}`
							: null}
					</p>
					<h2>{t("Left Eye")}</h2>
					<p>
						{`${Ptdata.FinalSphOS}, `}
						{t("CYL")} {`${Ptdata.FinalCylOS}, `}
						{t("AXIS")} {`${Ptdata.FinalCLAxisOS}`}
						{Ptdata?.CL_OldBaseCurveOS
							? `, ${t("BC")}: ${Ptdata.CL_OldBaseCurveOS}`
							: null}
						{Ptdata?.CL_OldDiamOS
							? `, ${t("DIAM")}: ${Ptdata.CL_OldDiamOS}`
							: null}
					</p>
				</div>
			</div>
		</div>
	);
}
