// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Resizable } from 're-resizable';
import cbill from '../assets/img/cbill.jpg';
import ubill from '../assets/img/ubill.png';
import card from '../assets/img/card2.png';
import './styles.css';
import { useTranslation } from 'react-i18next';

const qsName = 'placecard';
const PATH = "/wearing"; // '/shoesize';

export default function Page6placecard() {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    const questionText = t('Please take out a credit card or a bill');
    const explainText = t('Adjust the red border to fit around the perimeter of your bill or card. The red rectangle should be on the OUTSIDE.');
    const answerText1 = t('Done');
    // const Button1 = () => {
//valor do ratio do cartão de credito = 56.42078 - resolução de 2056 x 1329 - 1,54

//valor do ratio do cartão de credito = 47.54227 - resolução de 1728 x 1117
// 8,88 

// 8,29

//valor do ratio do cartão de credito = 35.27592 - resolução de 1920 x 1080 - 1,77

//valor do ratio do cartão de credito = 26.98152 - resolução de 2560 x 1440 - 1,77


    
// x default = 536,9618377058 - 522,04
// y default = 346 - 

// ((1,54 * 0,07775)*326 + 20)/8,560

// Regra para ajustar o ratio com base na resolução da tela

// ((1,54 / 1,084)*326 + 20)/8,560

//

    // objects[object].ratio * objects[object].multiplier + 20
        var query = "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

        // let scR = (size.x / objects[object].width).toFixed(5);
        const cardRatio = 8.560 / 5.398;

        let scR = ((cardRatio * (cardRatio * (window.screen.width/8.560))) * 0.1).toFixed(5);

        if (!matchMedia(query).matches) {
            scR = scR/2
        }    


        localStorage.setItem('ratio', scR)

        history.push({
            pathname: PATH,
        });
    // };

/*
DEFAULT 62,72


    Ratio - 61.21050 --- 2056 X 1329 - 0,77 (2056)/2 = 1028
    resize X 447.96183771767335
    resize Y 289.87593457943933
    16,57%
    60.39919
    
    Ratio - 52.33199 --- 1728 X 1117 - (1728)/2 = 864
    resize X 515.9618377176733
    resize Y 332.7572429906543
    3,91
    50.76352

objects[object].ratio * objects[object].multiplier + 20

(8,560/5,398)*((8,560/5,398)*(2056/8,5))*0,1
(cardRatio * (cardRatio * (window.screen.width/8.560)))*0,1





*/    


    // const cardRatio = 8.560 / 5.398;
    // const cBillRatio = 15.24 / 6.985;
    // const uBillRatio = 15.5956 / 6.6294;
    // const objects = [
    //     {
    //         name: t(' Credit Card'),
    //         ratio: cardRatio,
    //         width: 8.560,
    //         height: 5.398,
    //         image: card,
    //         multiplier: 326
    //     },
    //     {
    //         name: t(' Canadian Bill'),
    //         ratio: cBillRatio,
    //         width: 15.24,
    //         height: 6.985,
    //         image: cbill,
    //         multiplier: 200
    //     },
    //     {
    //         name: t(' U.S. Bill'),
    //         ratio: uBillRatio,
    //         width: 15.5956,
    //         height: 6.6294,
    //         image: ubill,
    //         multiplier: 200
    //     }
    // ];

    // const [object, setObject] = useState(0);
    // const [size, setSize] = useState({
    //     x: objects[object].ratio * objects[object].multiplier + 20,
    //     y: 1 * objects[object].multiplier + 20
    // });

    return  (
        <div></div>
    )

    // return (
    //     <div className='card-bill-screen'>

    //         <h2>{questionText}</h2>
    //         <h5>{explainText}</h5>

    //         <div>
    //             <div className='option-buttons'>
    //                 {
    //                     objects.map((item, key) => {
    //                         return <div onClick={() => {
    //                             setObject(key);
    //                             setSize({
    //                                 x: (item.ratio * item.multiplier) + 20,
    //                                 y: item.multiplier + 20
    //                             });
    //                         }} className="option-button">
    //                             <img src={item.image} alt="" style={{ height: '50px'}}/>
    //                             {item.name}
    //                         </div>;
    //                     })
    //                 }
    //             </div>
    //         </div>
    //         <div className='resize-viewport'>
    //             <Resizable
    //                 size={{ width: size.x, height: size.y }}
    //                 style={{ backgroundColor: 'lightblue', opacity: 0.8, border: '10px solid #FF0000' }}
    //                 lockAspectRatioExtraWidth={20}
    //                 lockAspectRatioExtraHeight={20}
    //                 lockAspectRatio={objects[object].ratio}
    //                 minWidth={120}
    //                 onResizeStop={(e, direction, ref, d) => {
    //                     setSize({ x: size.x + d.width, y: (size.x + d.width - 20) * (1/objects[object].ratio) + 20 }); //y: size.y + d.height
    //                 }}
    //             >
    //                 <img src={objects[object].image} alt="" style={{ width: '100%', height: '100%', opacity: '0.8' }}/>
    //             </Resizable>
    //         </div>
            
    //         {/* dev only: x: {size.x.toFixed(5)-20} y: {size.y.toFixed(5)-20} */}
            
    //         <div className="nav-view">
    //             <div className='nav-button' onClick={Button1}>{answerText1}</div>
    //         </div>
    //     </div>

    // );
}

//, marginRight: '5px'  #007bff

//page: place card