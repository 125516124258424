import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function KnowOldContacts() {
	const history = useHistory();
    const { t, i18n } = useTranslation();

	const qsName = "knowcontacts"; /*1. They know their glasses RX 
                               2. The know their glasses AND CL RX 
                               3. They don't know their glasses RX */

	const questionText = t("Do you know your current contact lens prescription?");
	const answerText1 = t("Yes");
	const answerText2 = t("No");

	const PATH_YES = "/contacts";
	const PATH_NO = "/haveglasses";

	const buttonAction = (qsName, answer, path) => {
		if (answer == answerText1) {
			localStorage.setItem("KnowContact", "yes");
		}
		if (answer == answerText2) {
			localStorage.setItem("KnowContact", "no");

			if (localStorage.getItem("KnowSpec") == "no") {
				path = "/placecard";
			}
		}
		history.push({
			pathname: path,
		});
	};

	return (
		<TestWrapper image={`images/test/006.jpg`}>
			<h2>{questionText}</h2>

			<div className="multiple-buttons">
				<div
					onClick={() => {
						buttonAction(qsName, answerText1, PATH_YES);
					}}
					className="button-default"
				>
					{answerText1}
				</div>

				<div
					onClick={() => {
						buttonAction(qsName, answerText2, PATH_NO);
					}}
					className="button-default"
				>
					<div>{answerText2}</div>
				</div>
			</div>
		</TestWrapper>
	);
}
