import { useTranslation } from "react-i18next";

export default function Modal({ visible, close, children }) {
	if (!visible) return null;

	return (
		<div className="modal-view">
			<div className="modal-inset">
				<button onClick={close} className="close">×</button>
				{children}
			</div>
		</div>
	);
}
