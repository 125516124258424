import React, { useState, useEffect } from "react";
import LoginLogo from "../assets/images/login-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { HashLink } from "react-router-hash-link";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { useTranslation } from "react-i18next";

function ResetPassword(props) {

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({});
	const [validToken, setValidToken] = useState(false);
	const history = useHistory();
	const { token } = useParams();

	
		useEffect(() => {
			if (token && validToken === false) {
				axios
					.get(`${process.env.REACT_APP_API}/user/change_password/${token}`, {
						withCredentials: true,
					})
					.then(function (res) {
						if (res?.data?.result) {
							setValidToken(true);
						}
					})
					.catch((e) => {
						let error = t("Token is invalid!!!");
						Swal.fire({
							icon: "error",
							title: "Ooopsss!",
							text: error,
						});
					});
			}
		}, []);

	const Reset = () => {		
		if (state.confirm_password !== state.password)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, confirm password is not equal to password"),
			});
		if (!state.password)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid password"),
			});
		if (!state.confirm_password)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a confirm password"),
			});


		setLoading(true);

		axios
			.post(`${process.env.REACT_APP_API}/user/change_password/${token}`, state, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result) {
					setLoading(false);
					Swal.fire({
						title: t("Your password has been reset successfully"),
						showCancelButton: false,
						confirmButtonText: t("Close"),
						allowOutsideClick: false,
						icon: "success",
					}).then(() => {
						history.push("/login");
					})
					
				} else {
					setLoading(false);
					let error = t("Is not possible to reset your password at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: t("Sorry"),
						text: error,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				let error = t("Is not possible to reset your password at this time. Please, try again later.");
				if (e.response.status === 400 && e.response?.data?.error) {
					error = e.response.data.error;
				}
				Swal.fire({
					icon: "error",
					title: t("Sorry"),
					text: error,
				});
			});
	};

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						{ validToken ? ( <div className="form-inset">
							<h2>{t("Reset Password")}</h2>
							<span>{t("Reset password text")}</span>

							<div className="form-group">
								<label>{t("New password")}</label>
								<input
									type="password"
									onChange={(e) => {
										setState((state) => {
											return {
												...state,
												password: e.target.value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Confirm password")}</label>
								<input
									type="password"
									onChange={(e) => {
										setState((state) => {
											return {
												...state,
												confirm_password: e.target.value,
											};
										});
									}}
								/>
							</div>

							<div className="form-options">
								{!loading ? (
									<button onClick={Reset}>{t("Continue")}</button>
								) : (
									<Spinner />
								)}
							</div>
						</div> ) : (
							<div className="form-inset">
							<h2>Ooopsss!</h2>
							<span>{t("Token is invalid!!!")}</span>
							<div className="not-registered">
								{t("Go to")} <HashLink to="/home">{t("Home")}</HashLink> {t("or")} <HashLink to="/login">{t("Sign in")}</HashLink>
							</div>
						</div>
						)}
						
					</div>
				</div>
			</main>
		</>
	);
}

export default ResetPassword;
