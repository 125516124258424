export const FunctionAddPower = (gender, age) => {
  age = age * 1;
  let AddPwr = 0;
  if (gender === "female") {
    if (age > 25 && age < 32) {
      AddPwr = "0.50";
    }
    if (age >= 32 && age < 40) {
      AddPwr = "1.00";
    }
    if (age >= 40 && age < 45) {
      AddPwr = "1.25";
    }
    if (age >= 45 && age < 50) {
      AddPwr = "1.75";
    }
    if (age >= 50 && age < 55) {
      AddPwr = "2.00";
    }
    if (age >= 55 && age < 60) {
      AddPwr = "2.25";
    }
    if (age >= 60 && age < 65) {
      AddPwr = "2.50";
    }
    if (age >= 65) {
      AddPwr = "2.75";
    }
  }
  if (gender === "male") {
    if (age >= 25 && age < 32) {
      AddPwr = "0.50";
    }
    if (age >= 32 && age < 40) {
      AddPwr = "1.00";
    }
    if (age >= 40 && age < 45) {
      AddPwr = "1.25";
    }
    if (age >= 45 && age < 50) {
      AddPwr = "1.50";
    }
    if (age >= 50 && age < 55) {
      AddPwr = "1.75";
    }
    if (age >= 55 && age < 60) {
      AddPwr = "2.00";
    }
    if (age >= 60 && age < 65) {
      AddPwr = "2.25";
    }
    if (age >= 65) {
      AddPwr = "2.50";
    }
  }
  return AddPwr;
};

export const FunctionEyeValence = (
  KnowSpec,
  KnowContact,
  OldSphOD,
  OldSphOS,
  OldCylOD,
  OldCylOS,
  Answer1a,
  Answer1b,
  CL_OldSphOD,
  CL_OldSphOS,
  CL_OldCylOD,
  CL_OldCylOS
) => {
  OldSphOD = OldSphOD * 1;
  OldSphOS = OldSphOS * 1;
  OldCylOD = OldCylOD * 1;
  OldCylOS = OldCylOS * 1;
  CL_OldSphOD = CL_OldSphOD * 1;
  CL_OldCylOD = CL_OldCylOD * 1;
  CL_OldSphOS = CL_OldSphOS * 1;
  CL_OldCylOS = CL_OldCylOS * 1;

  if (Answer1a === "not sure" || Answer1a === "unknown") {
    Answer1a = "not sure";
  }
  if (Answer1b === "not sure" || Answer1b === "unknown") {
    Answer1b = "not sure";
  }

  let eyeValenceOD = "undetermined";
  let eyeValenceOS = "undetermined";
  let eyeValenceSafety = "pass";

  if (KnowSpec === "yes") {
    eyeValenceOD = getValencefromOldRx(OldSphOD, OldCylOD);
    eyeValenceOS = getValencefromOldRx(OldSphOS, OldCylOS);
  } else if (KnowContact === "yes") {
    eyeValenceOD = getValencefromOldRx(CL_OldSphOD, CL_OldCylOD);
    eyeValenceOS = getValencefromOldRx(CL_OldSphOS, CL_OldCylOS);
  } else {
    if (Answer1a === "near" && Answer1b === "near") {
      eyeValenceOD = "myopic";
      eyeValenceOS = "myopic";
    }
    else if (Answer1a === "far" && Answer1b === "far") {
      eyeValenceOD = "hyperopic";
      eyeValenceOS = "hyperopic";
    }
    else if ((Answer1a !== "near" && Answer1a !== "far") && Answer1b === "near") {
      eyeValenceOD = "myopic";
      eyeValenceOS = "myopic";
    }
    else if (Answer1a === "near" && (Answer1b !== "near")) {
      eyeValenceOD = "myopic";
      eyeValenceOS = "myopic";
    }
    else if ((Answer1a !== "near" && Answer1a !== "far") && Answer1b === "far") {
      eyeValenceOD = "hyperopic";
      eyeValenceOS = "hyperopic";
    }
    else if (Answer1a === "far" && (Answer1b !== "far")) {
      eyeValenceOD = "hyperopic";
      eyeValenceOS = "hyperopic";
    }
  }

  function getValencefromOldRx(OldSph, OldCyl) {
    let EyeValence = "";
    let EV = 0;
    if (OldSph === 0) {
      if (OldCyl === 0) {
        EV = "Emmetropia";
      }
    }
    if (OldSph === 0) {
      if (Math.abs(OldCyl) > 0) {
        EV = "Simple Myopic Astigmatism";
      }
    }
    if (OldSph < 0) {
      if (Math.abs(OldCyl) > 0) {
        EV = "Compound Myopic Astigmatism";
      }
      if (OldCyl === 0) {
        EV = "Simple Myopia";
      }
    }

    if (OldSph > 0) {
      if (OldSph - Math.abs(OldCyl) === 0) {
        EV = "Simple Hyperopic Astigmatism";
      }
      if (OldCyl === 0) {
        EV = "Simple Hyperopia";
      }
      if (Math.abs(OldCyl) > OldSph) {
        EV = "Mixed Astigmatism";
      }
      if (Math.abs(OldCyl) < OldSph) {
        EV = "Compound Hyperopic Astigmatism";
      }
    }

    if (EV === "Simple Myopia") {
      EyeValence = "myopic";
    }
    if (EV === "Compound Myopic Astigmatism") {
      EyeValence = "myopic";
    }
    if (EV === "Simple Myopic Astigmatism") {
      EyeValence = "myopic";
    }
    if (EV === "Simple Hyperopia") {
      EyeValence = "hyperopic";
    }
    if (EV === "Compound Hyperopic Astigmatism") {
      EyeValence = "hyperopic";
    }
    if (EV === "Simple Hyperopic Astigmatism") {
      EyeValence = "hyperopic";
    }
    if (EV === "Mixed Astigmatism") {
      EyeValence = "mixed";
    }

    //console.log("Based on Old Rx, EyeValence was set to: ", EyeValence)
    return EyeValence;
  }

  if (
    eyeValenceOD === "undetermined" ||
    eyeValenceOS === "undetermined" ||
    eyeValenceOD !== eyeValenceOS
  ) {
    //if either eyeValence is undetermined OR if the eyeValence between both eyes is not eqal then the eyeValenceSafety is triggered to fail. ALSO. To keep the app going forward, the eyeValences are overwritten to be MYOPIC
    eyeValenceSafety = "fail";
    // eyeValenceOD = "myopic"
    // eyeValenceOS = "myopic"
  }

  // console.log("eye valence for right eye =" + eyeValenceOD)
  // console.log("eye valence for left eye =" + eyeValenceOS)
  // console.log("eyeValence Safety = " + eyeValenceSafety)

  // localStorage.setItem('EyeValenceOD', eyeValenceOD)
  // localStorage.setItem('EyeValenceOS', eyeValenceOS)
  // localStorage.setItem('EyeValenceSafety', eyeValenceSafety)

  return {
    EyeValenceOD: eyeValenceOD,
    EyeValenceOS: eyeValenceOS,
    EyeValenceSafety: eyeValenceSafety,
  };
};

export const SPEMethods = (EyeValence, VA, Age) => {
  VA = VA * 1; // this keeps the incoming numbers AS NUMBERS (rather than acting like strings)
  Age = Age * 1;

  let Weight = 0;
  let SPE = 0;

  if (EyeValence === "myopic") {
    if (VA < 140) {
      SPE = -2 * (Math.log10(VA) - 1.25); // Method 1
    } else {
      SPE = (VA + 17.343) / -86.464; //Method 6
    }
 

  }

  if (EyeValence === "hyperopic") {
    let M3 = Math.log(VA / 7.0043) / 0.466; // Method 3 (for hyperopes A5-15)
    let M4 = Math.log(VA / 11.117) / 0.4384; // Method 4 (for hyperopes A: 25 -35)
    let M5 = Math.log(VA / 8.6093) / 0.8548; // Method 5 (for hyperopes A: 45-55)
    if (Age <= 15) {
      SPE = M3; // Method 3 (for hyperopes A5-15)
    }
    if (Age > 15 && Age <= 25) {
      Weight = (Age - 15) / 10;
      SPE = (1 - Weight) * M3 + Weight * M4;
    }
    if (Age >= 25 && Age <= 35) {
      SPE = M4;
    }
    if (Age > 35 && Age < 45) {
      Weight = (Age - 35) / 10;
      SPE = (1 - Weight) * M4 + Weight * M5;
    }
    if (Age >= 45) {
      SPE = M5;
    }
  }

  return SPE;
  //console.log ("SPE based on age and eyevalence is:  " +SPE )
};

export const FunctionMeasuredRx = (SPE, A, BradsPrimeDrPOV, EyeValence) => {
  SPE = SPE * 1;
  BradsPrimeDrPOV = BradsPrimeDrPOV*1 //NOTE BradsPrimeDrPOV is being taking from localStorage('PrimeDrPOV') and it's off by 90 degrees from the REAL PrimeDrPOV which the if statemtent below corrects

  let PrimeDrPOV = Number(BradsPrimeDrPOV -90)
  if (BradsPrimeDrPOV<90){
     PrimeDrPOV = Number(BradsPrimeDrPOV +90)
  }


  let Theta = PrimeDrPOV
  A = A*1
  Theta = Theta * 1;

  let SPH = 0;
  let CYL = 0;
  let Axis = 0;

  let B = 2 * SPE - A; 

  if (EyeValence === "myopic") {
    //applies to CMA and simple myopic astigmatism
    SPH = A;
    CYL = B - A;
    Axis = Theta;


    //CONDITION 1a: If VAsc is better than VAa we can assume that Cyl is 0. Then we can set SPH to [(SPEsc+SPEa)/2]
    //REMOVED CONDITION 1b: Also If difference between SPE and A is less than 0.4 then assume Cyl is 0 and do the same as condition 1a
    if (SPE > A ) {
      SPH = (SPE + A) / 2;
      CYL = 0;
    }
    //CONDITION 2: If the Rx is supposed to be slitghtly MYOPIC SPH should not be positive. Therefore; if SPH is going to be positive, change it to 0
    if(SPH >0){
        SPH = 0 // this will also mean less over estimating Cyl values
    }

    //Tratativa para deixar o CYL sempre negativo
    // CYL = CYL < 0 ? CYL * -1 : CYL;
  }
  if (EyeValence === "hyperopic") {
    //applies to CHA and simple hyperopic astigmatism
    SPH = B;
    CYL = A - B;
    Axis = Theta * 1 + 90;

    
  }
  if (EyeValence === "mixed") {
    if (A > B) {
      SPH = A;
      CYL = B - A;
      Axis = Theta * 1;
    }
    if (A < B) {
      SPH = B;
      CYL = A - B;
      Axis = Theta * 1 + 90;
    }
    if (SPE === 0) {
      SPH = A;
      CYL = -2 * A;
      Axis = Theta;
    }
    if (Theta === "NA") {
      if (SPE > 0) {
        EyeValence = "Simple Hyperope";
      }
      if (SPE < 0) {
        EyeValence = "Simple Myope";
      }
      SPH = SPE;
      CYL = "Sph";
      Axis = " ";
    }
  }
  //Tratativa para deixar o CYL sempre negativo
  CYL = CYL > 0 ? CYL * -1 : CYL;

  return {
    Sphere: SPH,
    Cylinder: CYL,
    Axis: Axis,
  };
};

export const FunctionWeightedRx = (
  VAcc,
  OldSph,
  OldCyl,
  OldAxis,
  MeasuredSph,
  MeasuredCyl,
  MeasuredAxis,
  HaveSpec = "no"
) => {
  VAcc = VAcc * 1;
  OldSph = OldSph * 1;
  OldCyl = OldCyl * 1;
  MeasuredSph = MeasuredSph * 1;
  MeasuredCyl = MeasuredCyl * 1;
  MeasuredAxis = MeasuredAxis * 1;

  if (OldAxis === "no axis") {
    OldAxis = MeasuredAxis;
  } else {
    OldAxis = OldAxis * 1;
  }

  // let Weight = (70 - VAcc) / 50 //(120-VAcc)/100 // this is the formula that is used to determine the relative usefullness of the patient's CURRENT prescription (Ex: if they see REALLY well with their old glasses than we don't need to stray from those numbers much)
  let Weight;
  if (VAcc > 40 || HaveSpec || localStorage.getItem("HaveSpec") === "no") {
    Weight = 0;
  } else if (VAcc <= 20) {
    Weight = 1;
  } else {
    Weight = (40 - VAcc) / 20;
  }

  // console.log("Weighted importance of current glasses Rx is: %", Weight * 100)
  // console.log("OldSph =" + OldSph)

  let FinalSph = OldSph * Weight + MeasuredSph * (1 - Weight);
  let FinalCyl = OldCyl * Weight + MeasuredCyl * (1 - Weight);


  //CONDITION 3 Averaging axis' that are in different quadrants
  let FinalAxis = OldAxis * Weight + MeasuredAxis * (1 - Weight);

  //Old Axis = 178 and Measured Axis = 2

  // FinalAxis = (OldAxis * Weight + MeasuredAxis * (1 - Weight))+180;
  
  OldAxis = OldAxis
  MeasuredAxis = MeasuredAxis

  let axis_x = 0
  let axis_y = 0

  axis_x = axis_x + Math.cos(OldAxis*(Math.PI/90)) * Weight
  axis_y = axis_y + Math.sin(OldAxis*(Math.PI/90)) * Weight
  
  axis_x = axis_x + Math.cos(MeasuredAxis*(Math.PI/90)) * (1- Weight )
  axis_y = axis_y + Math.sin(MeasuredAxis*(Math.PI/90)) * (1- Weight )

  FinalAxis = Math.round(((Math.atan2(axis_y, axis_x) * 180 / Math.PI + 360) % 360) / 2)
  
  
  console.log("axis_x: "+ axis_x + "axis_y: " + axis_y + "Final Axis: " + FinalAxis)
  ///ROUND TO NEAREST 0.25D
  FinalSph = (Math.round(FinalSph * 4) / 4).toFixed(2);
  FinalCyl = (Math.round(FinalCyl * 4) / 4).toFixed(2);

  ///Condition 1: When the VAa is worse than VAsc the asume the Cyl is Zero. Then set SPH power to equal the SPE

  //Beautify final Spec Rx
  if (FinalCyl == 0) {
    FinalCyl = "Sph";
    FinalAxis = "no axis";
  }

  return {
    FinalSph: FinalSph,
    FinalCyl: FinalCyl,
    FinalAxis: FinalAxis,
  };
};

// export const FunctionProcessedFinalRx = () => {
//     let KnowSpec = localStorage.getItem('KnowSpec')
//     let HaveSpec = localStorage.getItem('HaveSpec')
//     let KnowContact = localStorage.getItem('KnowContact')

//     let CL_OldBrandOD = localStorage.getItem('CL_OldBrandOD')
//     let CL_OldBrandOS = localStorage.getItem('CL_OldBrandOS')

//     let OldSphOD = localStorage.getItem('OldSphOD')
//     let OldSphOS = localStorage.getItem('OldSphOS')
//     let OldCylOD = localStorage.getItem('OldCylOD')
//     let OldCylOS = localStorage.getItem('OldCylOS')
//     let OldAxisOD = localStorage.getItem('OldAxisOD')
//     let OldAxisOS = localStorage.getItem('OldAxisOS')

//     let FinalSphOD = localStorage.getItem('FinalSphOD')
//     let FinalCylOD = localStorage.getItem('FinalCylOD')
//     let FinalAxisOD = localStorage.getItem('FinalAxisOD')

//     let FinalSphOS = localStorage.getItem('FinalSphOS')
//     let FinalCylOS = localStorage.getItem('FinalCylOS')
//     let FinalAxisOS = localStorage.getItem('FinalAxisOS')

//     FinalSphOS = (Math.round(FinalSphOS * 4) / 4).toFixed(2);
//     FinalCylOS = (Math.round(FinalCylOS * 4) / 4).toFixed(2);
//     FinalAxisOD = (Math.round(FinalAxisOD * 100) / 100).toFixed(0);
//     FinalAxisOS = (Math.round(FinalAxisOS * 100) / 100).toFixed(0);

//     localStorage.setItem('FinalSphOD',FinalSphOD )
//     localStorage.setItem('FinalCylOD',FinalCylOD )
//     localStorage.setItem('FinalAxisOD',FinalAxisOD )
//     localStorage.setItem('FinalSphOS',FinalSphOS )
//     localStorage.setItem('FinalCylOS',FinalCylOS )
//     localStorage.setItem('FinalAxisOS',FinalAxisOS )
// }
