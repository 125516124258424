import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../components/Header";
import ErrorView from "../components/Error";
import Footer from "../Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";

function HomePage() {
	let history = useHistory();
	const { t, i18n } = useTranslation();

	const [saveStatus, setSaveStatus] = useState("idle");
	const [status, setStatus] = useState("loading");
	const [state, setState] = useState(null);

	const PNF = require('google-libphonenumber').PhoneNumberFormat;
	const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

	const listI18nToGooglePhone = {
		"en": "US",
		"pt-BR": "BR",
		"es": "ES",
		"it": "IT",
	}

	const cleanPhoneNumber = (phoneNumber) => {
		const number = phoneUtil.parseAndKeepRawInput(String(phoneNumber), listI18nToGooglePhone[i18n.language]);	
		console.log('number', number.values_[2]);
		return number.values_[2];
	}

	const formatPhoneNumber = (phoneNumber) => { 
		if (!phoneNumber) return '';
		const convertPhoneNUmber = String(phoneNumber);
		if (convertPhoneNUmber.length > 4) {
			const number = phoneUtil.parseAndKeepRawInput(convertPhoneNUmber, listI18nToGooglePhone[i18n.language]);	
			console.log('number', number.values_[2])
			return phoneUtil.format(number, PNF.INTERNATIONAL);
		}	
		return convertPhoneNUmber
		
	}

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API}/user/user-info`, {
				withCredentials: true,
			})
			.then(function (res) {
				if (res.data.result) {
					if (res.data.user && res.data.user.mobileNumber) res.data.user.mobileNumber = cleanPhoneNumber(res.data.user.mobileNumber);
					setState(res.data.user);
					setStatus("done");
				}
			})
			.catch((e) => {
				setStatus("error");
			});
	}, []);

	const save = () => {
		if (!state.name)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid name"),
			});
		if (!state.lastName)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid last name"),
			});
		if (!state.email)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid e-mail"),
			});

		const { email, name, lastName, mobileNumber } = state;
		let payload = {
			email,
			name,
			lastName,
			mobileNumber: formatPhoneNumber(mobileNumber),
		};

		if (state.password || state.confirm_password || state.current_password) {
			console.log("check");
			if (!state.current_password)
				return Swal.fire({
					icon: "error",
					title: "Oops...",
					text: t("Please, insert your current password"),
				});
			if (!state.password)
				return Swal.fire({
					icon: "error",
					title: "Oops...",
					text: t("Please, insert your new password"),
				});
			if (!state.confirm_password)
				return Swal.fire({
					icon: "error",
					title: "Oops...",
					text: t("Please, confirm your new password"),
				});
			if(state.confirm_password !== state.password) {
				return Swal.fire({
					icon: "error",
					title: "Oops...",
					text: t("Your passwords does not match"),
				});
			}

			payload.password = state.password;
			payload.current_password = state.current_password;
		}

		setSaveStatus("loading");

		axios
			.post(
				`${process.env.REACT_APP_API}/user/user-info`,
				{
					...payload,
				},
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				console.log(res);
				setSaveStatus("idle");
			})
			.catch((e) => {
				console.log(e);
				setSaveStatus("idle");

				return Swal.fire({
					icon: "error",
					title: "Error",
					text: e?.response?.data?.error || t('Could not save at this time. Please try again later.'),
				});
			});
	};

	return (
		<>
			<Header />

			<div className="content">
				<main className="user-page">
					<h1>{t("My Profile")}</h1>

					{status === "loading" ? (
						<div className="loading-view auto">
							<div className="spinner-wrapper">
								<Spinner />
							</div>
						</div>
					) : null}

					{status === "done" && state ? (
						<div className="user-profile-form">
							<div className="col">
								<h2>{t("Basic Info")}</h2>

								<div className="form-group">
									<label>{t("Your name")}</label>
									<input
										type="text"
										value={state?.name || ""}
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													name: e.target.value,
												};
											});
										}}
									/>
								</div>
								<div className="form-group">
									<label>{t("Your last name")}</label>
									<input
										type="text"
										value={state?.lastName || ""}
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													lastName: e.target.value,
												};
											});
										}}
									/>
								</div>

								<div className="form-group">
									<label>{t("Your e-mail")}</label>
									<input
										type="text"
										value={state?.email || ""}
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													email: e.target.value,
												};
											});
										}}
									/>
								</div>
								<div className="form-group">
									<label>{t("Mobile Number")}</label>
									<input
										type="text"
										value={state?.mobileNumber || ""}
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													mobileNumber: e.target.value,
												};
											});
										}}
									/>
								</div>
							</div>

							<div className="col">
								<h2>{t("Change your password")}</h2>

								<div className="form-group">
									<label>{t("Current password")}</label>
									<input
										type="password"
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													current_password: e.target.value,
												};
											});
										}}
									/>
								</div>

								<div className="form-group">
									<label>{t("New password")}</label>
									<input
										type="password"
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													password: e.target.value,
												};
											});
										}}
									/>
								</div>

								<div className="form-group">
									<label>{t("Confirm new password")}</label>
									<input
										type="password"
										onChange={(e) => {
											setState((s) => {
												return {
													...s,
													confirm_password: e.target.value,
												};
											});
										}}
									/>
								</div>
							</div>

							<div className="action">
								{saveStatus !== "loading" ? (
									<button onClick={save}>{t("Continue")}</button>
								) : (
									<Spinner />
								)}
							</div>
						</div>
					) : null}
					{status === "error" && <ErrorView />}
				</main>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
