import { useTranslation } from 'react-i18next';

export default function AudioGuide({lang, sound}) {
  const { t } = useTranslation();

  const initializeAudio = () => {
		let audioGuide = new Audio(`./sounds/${sound}-${lang}.mp3`)
		audioGuide.play()
	}

  return (
    <div onClick={initializeAudio} style={{color: "#216e75", fontSize: ".9rem", fontWeight: "500", textDecoration: "none", marginBottom: "-40px"}}>
    {t("Audio Guide")}
  </div>
  )
}