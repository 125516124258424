import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import { useTranslation } from 'react-i18next';

export default function HaveGlasses() {

    const { t, i18n } = useTranslation();

	const qsName = "havespec"; /*1. They know their glasses RX 
                               2. The know their glasses AND CL RX 
                               3. They don't know their glasses RX */

	const questionText = t("Do you have your old glasses with you?");
	const answerText1 = t("Yes");
	const answerText2 = t("No");

	const PATH_YES = "/placecard";
	const PATH_NO = "/placecard";

	// if(ally == "no" && brad == "no"){
	//     history.push({
	//         pathname: '/placecard',
	//     });
	// }

	const history = useHistory();

	const buttonAction = (qsName, answer, path) => {
		if (answer == answerText1) {
			//what happens if answer2 is chosen
			localStorage.setItem("HaveSpec", "yes");
		}
		if (answer == answerText2) {
			//what happens if answer2 is chosen
			localStorage.setItem("HaveSpec", "no");
		}
		history.push({
			pathname: path,
		});
	};

	return (
		<div className="frame">
			<h2>{questionText}</h2>

			<div className="answerArea">
				<div
					onClick={() => {
						buttonAction(qsName, answerText1, PATH_YES);
					}}
					className="Button2"
				>
					{answerText1}
				</div>

				<div
					onClick={() => {
						buttonAction(qsName, answerText2, PATH_NO);
					}}
					className="Button2"
				>
					<div>{answerText2}</div>
				</div>

				{/* <div onClick={() => {buttonAction( qsName,"YES", PATH_YES);}}className='button'>
                {answerText1}
            </div>
            
            <div onClick={() => {buttonAction(qsName,"NO", PATH_NO);}} className='button'>
                <div>{answerText2}</div>
            </div> */}
			</div>
		</div>
	);
}
