// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './styles.css';
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';


export default function Page2Age() {

    let history = useHistory();
    const { t, i18n } = useTranslation();

    const [state, setState] = useState({});

    const buttonAction = () => {

        console.log(state);

        if(!state.name || state.name === '') return alert(t("Please, insert a valid name"));
        if(!state.email || state.email === '') return alert(t("Please, insert a valid e-mail"));
        if(!state.phone || state.phone === '') return alert(t("Please, insert a valid phone number"));

        localStorage.setItem("name", state.name);
        localStorage.setItem("phone", state.phone);
        localStorage.setItem("email", state.email);

        history.push({
                pathname: '/age',
        });
    };

    return (

        <TestWrapper image={`images/test/002.jpg`}>

            <h2>{t("Tell us about you")}</h2>

            <div className="form-view">
                <Form>
                    <Form.Group>
                        <Form.Control type="text" placeholder={t("Your name")} size={'lg'} min={1900} max={2022}
                            onChange={(v) => {

                                console.log(v.target.value);

                                setState(state => {
                                    return {
                                        ...state,
                                        name: v.target.value
                                    }
                                })
                            }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group>
                        <Form.Control type="text" placeholder={t("Your e-mail")} size={'lg'} min={1900} max={2022}
                            onChange={(v) => {
                                 setState(state => {
                                    return {
                                        ...state,
                                        email: v.target.value
                                    }
                                })
                            }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group>
                        <Form.Control type="text" placeholder={t("Your phone")} size={'lg'} min={1900} max={2022}
                            onChange={(v) => {
                                 setState(state => {
                                    return {
                                        ...state,
                                        phone: v.target.value
                                    }
                                })
                            }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>

                
            </div>

            <div className="nav-view">
                <div
                    onClick={() => buttonAction()}
                    className="nav-button"
                >
                    <div>{t("Submit")}</div>
                </div>
            </div>


        </TestWrapper>

    );



}
