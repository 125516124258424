// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import qs from "query-string";
import { Button } from "react-bootstrap";
import "./styles.css";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from 'react-i18next';
import AudioGuide from '../components/AudioGuide'

function MPageTest1A(props) {
	const [state, setState] = useState({ isSession: false });
     const { t, i18n } = useTranslation();

	useEffect(() => {
		fetch(
			URL_ROUTE_USER +
				"/updateStatus" +
				window.location.search +
				"&pageStatus=3"
		)
			.then((response) => response.json())
			.then((data) => {
				console.log("updated");
				console.log(data);
			});
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const query = qs.parse(window.location.search);
	// if (!state.isSession) return <h1 style={{ marginTop: '100px' }}>NO SESSION</h1>;

	const Button1 = (val) => {
		if (val == 1) {
			localStorage.setItem("Answer1a", "near");
		} else if (val == 2) {
			localStorage.setItem("Answer1a", "far");
		} else if (val == 4) {
			localStorage.setItem("Answer1a", "not sure");
		}

		fetch(
			URL_ROUTE_USER + "/sendAnswer" + window.location.search + `&answer=${val}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.message == "accepted") {
					console.log(data);
					const searchString = qs.stringify(query);
					props.history.replace({
						pathname: "/mtest1B",
						search: searchString,
					});
					props.history.push({
						pathname: "/mtest1B",
						search: searchString,
					});
				}
			});
	};
	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("Question 1")}</h1>
					<h2>{t("What is easier for you to see when you don't have your glasses?")}</h2>
					<div className="nav-buttons">
						<div className="nav-button" onClick={() => Button1(1)}>
							{t("Near Objects")}
						</div>
						<div className="nav-button" onClick={() => Button1(2)}>
							{t("Far Objects")}
						</div>
						<div className="nav-button" onClick={() => Button1(4)}>
							{t("Not Sure")}
						</div>
					</div>
					<div style={{marginTop: "30px" }}>
						<AudioGuide 
							lang={query.lang}
							sound="mpage14test1a"
						/>
					</div>
					
				</div>
			</div>
		</>
	);
}

export default withRouter(MPageTest1A);
