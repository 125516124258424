import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";

import { useTranslation } from "react-i18next";

export default function Page3Gender() {
	let history = useHistory();
    const { t, i18n } = useTranslation();

	const qsName = "gender";
	const questionText = t("What is your gender?");
	const answerText1 = t("Male");
	const GENDER_MALE = 1;
	const PATH_GENDER_MALE = "/knowoldspecs";
	const answerText2 = t("Female");
	const GENDER_FEMALE = 2;
	const PATH_GENDER_FEMALE = "/knowoldspecs";
	const answerText3 = t("Other");

	const buttonAction = (qsName, val, path) => {
		let gender = "";
		if (val == 1) {
			gender = "male";
			let steps = Math.round(304.8 / 28.2);
			localStorage.setItem("NumberOfSteps", steps);
		} else {
			gender = "female";
			let steps = Math.round(304.8 / 25);
			localStorage.setItem("NumberOfSteps", steps);
		}
		localStorage.setItem("gender", gender);
		history.push({
			pathname: path,
		});
	};
	return (
		<TestWrapper image={`images/test/003.jpg`}>
			<h2>{questionText}</h2>

			<div className="multiple-buttons">
				<div
					onClick={() => buttonAction(qsName, GENDER_MALE, PATH_GENDER_MALE)}
					className="button-default"
				>
					<div>{answerText1}</div>
				</div>
				<div
					onClick={() =>
						buttonAction(qsName, GENDER_FEMALE, PATH_GENDER_FEMALE)
					}
					className="button-default"
				>
					<div>{answerText2}</div>
				</div>
				<div
					onClick={() =>
						buttonAction(qsName, GENDER_FEMALE, PATH_GENDER_FEMALE)
					}
					className="button-default"
				>
					<div>{answerText3}</div>
				</div>
			</div>
		</TestWrapper>
	);
}
