import { withRouter } from "react-router-dom";
import React from "react";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function MPageEndOfTest(props) {

    const { t, i18n } = useTranslation();

	useEffect(() => {
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	return (
		<div className="end-test">
			<h1>{t("End of Test")}</h1>
			<p>{t("Please close this page and continue on PC.")}</p>
		</div>
	);
}


export default withRouter(MPageEndOfTest);
