import homePageImg from './assets/img/homepage_home.jpeg';
import homePageAboutImg from './assets/img/homepage_about.jpg';
import glassesPic from './assets/img/glasses.jpg';
import { Button, Collapse, Accordion, Card } from 'react-bootstrap';
import style from './HomePage.css';
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { useHistory, Link } from 'react-router-dom';
import { isMobile } from './utils';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { ReactComponent as Spinner } from "./assets/images/spinner.svg";

function nl2br (str, replaceMode, isXhtml) {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  }

  function br2nl (str, replaceMode) {  
    var replaceStr = (replaceMode) ? "\n" : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
  }


function HomePage({ user, statusLoading }: any) {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    const isTrial = process.env.REACT_APP_TRIAL

	const captchaRef = useRef(null);
    const RECAPTCHA_SECRET=process.env.REACT_APP_RECAPTCHA_SECRET;
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);

    const imageMoneyBack = i18n.language === "pt-BR" ? "images/100-dinheiro-de-volta.webp" : "images/money-back.png";

	const submit = () => {

		if (!state.email)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid e-mail"),
			});
		if (!state.name)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a name Contact"),
		    });
		if (!state.message)
            return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: t("Please, insert a message Contact"),
             });

		if (!state.token)
            return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: t("Please, check on captcha verification"),
            });

		setLoading(true);
        state.message = nl2br(state.message, true, false);

		axios
			.post(`${process.env.REACT_APP_API}/user/contact`, state )
			.then((res) => {
				setLoading(false);
                setState({});
                Swal.fire({
                    icon: "success",
                    title: t("Contact send Contact"),
                    text: t("Your message has been sent. We will contact you soon. Contact"),
                });
                
			})
			.catch((e) => {
				setLoading(false);
                state.message = br2nl(state.message, true);
				Swal.fire({
					icon: "error",
					title: t("Trouble in sending message Contact"),
					text: t("try again later Contact"),
				});
			});
            captchaRef.current.reset();
	};


    const [about1, setAbout1] = useState(false);
    const [about2, setAbout2] = useState(false);
    const [about3, setAbout3] = useState(false);
    
    return (

        <main>
            <section class="hero">
                <div class="info">
                    <div class="content">
                        <div class="info-inset">
                            {!user && statusLoading === "done" && (<h1>{t("Online Vision Test")}</h1>)}
                            {!user && statusLoading === "done" && (<h3>{t("Get Your Prescription Today")}</h3>)}
                            
                            {user && statusLoading === "done" && (<h1>{t("Online Vision Test1")}</h1>)}
                            {user && statusLoading === "done" && (<h3>{t("Get Your Prescription Today1")}</h3>)}
                            
                            <div className="wrapper-start-signup">
                                <button class="button"
                                    onClick={() => {
                                        localStorage.clear() //this clears all the localStorage data to start a "fresh" test condition
                                        // history.push({
                                        //     pathname: '/start'
                                        // });
                                        if (user && statusLoading === "done") {
                                            if (isMobile()) {
                                                alert(t("You need to start with a PC/Mac browser, not mobile"))
                                                return;
                                            }
                                            window.location.href = "/start";
                                        } else {
                                            window.location.href = "/signup";
                                        }
                                    }}
                                >{!user && statusLoading === "done" ? t("Sign Up") : t("Get started")}</button>
                            </div>
                            {!isTrial && (<img src={imageMoneyBack} alt="Eyetesters - money back guarantee" />)}
                        </div>
                    </div>
                </div>
                <img src="images/hero.jpg" alt="" />
            </section>
            <div class="content">
                <a id="about" name="about"></a>
                <section class="start">
                    <div class="image">
                        <img src="images/img1.jpg" alt="" />
                    </div>
                    <div class="info">
                        <div class="text-content">
                            <h2>{t("Online Vision Test")}</h2>
                            <p>{t("One of the most annoying things about ordering glasses online has been that you would have to visit an optometrist in order to get your prescription numbers. This often involves having to say “NO!” about a dozen times to the sales pitches that your optometrist will give you, even if you don't have vision problems.")}</p>

                            <p>{t("Now, you can skip this step when you visit EyeTesters.com because we offer a virtual vision test. This vision test works with a variety of different monitor sizes, and also can be used by adults and children alike. It's a great way to get a clue about how much help your vision will need. This vision test runs on the 'E Game' which uses capital E's of various sizes and orientations to judge around how high of a prescription you will need.")}</p>

                            <p>{t("This isn't only very convenient - it also keeps you from having to get hassled by optometrists who are desperate to sell you overpriced glasses!")}</p>
                        </div>
                    </div>
                </section>

                <section class="simple">
                    <div class="info">
                        <div class="text-content">
                            <h2>{t("Ordinary Vision Test")}</h2>
                            <p>{t("The ordinary vision test shall be given in good light by using a printed eye chart placed upright on the wall. The person to be tested should stand at the the required distance (for example, 10 feet, or as determined in other case). The person to be tested should cover one eye with the other open.")}</p>

                            <p>{t("The visual acuity of the opened eye can be judged according to the lowest line that can be read correctly. When testing children, it is important to keep their covered eye truly covered. In order to avoid the peeking through fingers, an opaque object can be used to cover the eye that is not being tested.")}</p>
                        </div>
                    </div>
                    <div class="images">
                        <img src="images/img2.jpg" alt="" />
                    </div>
                </section>
                {!isTrial && (<section class="simple">
                    <div class="images" style={{ textAlign: 'center'}}>
                        <img src={imageMoneyBack} alt="" style={{maxWidth: '60%'}}/>
                    </div>
                    <div class="info" style={{marginLeft: '50px', marginRiger: '0'}}>
                        <div class="text-content">
                            <h2>{t("title money back")}</h2>
                            <p>{t("text money back 1")}</p>

                            <p>{t("text money back 2")}</p>

                            <p>{t("text money back 3")}</p>
                        </div>
                    </div>
                </section>)}
            </div>

            {/* <section class="prescription">
                <div class="content">

                    <h2>{t("Get your prescription now")}</h2>

                    <div class="list">
                        <div class="item">
                            <div class="image">
                                <img src="images/img3.jpg" alt="" />
                            </div>
                            <div class="info">
                                <h4>{t("Instant Online Prescription")}</h4>
                                <span>$30</span>
                                <button class="button"
                                    onClick={() => {
                                        setAbout1(!about1);
                                        setAbout2(false)
                                        setAbout3(false)
                                    }}
                                >{t("Get started")}</button>
                            </div>
                        </div>
                        <div class="item">
                            <div class="image">
                                <img src="images/img4.jpg" alt="" />
                            </div>
                            <div class="info">
                                <h4>{t("REVIEWED Online Prescription")}</h4>
                                <span>$50</span>
                                <button class="button"
                                    onClick={() => {
                                        setAbout2(!about2);
                                        setAbout1(false)
                                        setAbout3(false)
                                    }}
                                >{t("Get started")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}

            <section class="prescription">
                <div class="content">
                    <a id="contact" name="contact"></a>
                    <h2>{t("Title Contact")}</h2>

                    <div class="wrapper-form-contact" >
                        <div class="form-contact">
                            <div className="form-group">
                                <label>{t("Name Contact")}</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={state?.name || ""}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												name: value,
											};
										});
									}}
                                />
                            </div>
                            <div className="form-group">
                            <label>{t("Email Contact")}</label>
                                <input
                                    type="text"
                                    name="email"
                                    value={state?.email || ""}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												email: value,
											};
										});
									}}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t("Message Contact")}</label>
                                <textarea
                                    name="message"
                                    value={state?.message || ""}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												message: value,
											};
										});
									}}
                                ></textarea>
                            </div>
                            <div className="recatpcha">
								<ReCAPTCHA
									sitekey={RECAPTCHA_SECRET}
									ref={captchaRef}
									onChange={(token) => {
										setState((state) => {
											return {
												...state,
												token,
											};
										});
									}}
								/>
							</div>
                            <div className="form-group" style={{margin: '20px 0'}}>
                            {!loading ? <button class="button" onClick={submit}>{t("Submit")}</button> : <span style={{background: 'white', padding: '5px 10px', borderRadius: '10px'}}><Spinner /> {t("Sending message Contact")}</span> }
                            </div>
                            
                        </div>
                    </div>

                </div>
            </section>

            <div class="content">
                <section class="faq">
                    <div class="info">
                        <h2>{t("Frequently Asked Questions")}</h2>
                        <span>{t("Here are a few frequently asked questions to help you understand if an online vision test is right for you.")}</span>
                    </div>
                    <div class="list">
                        <div class="item">
                            <h4>{t("Who can take the online vision test?")}</h4>
                            <div class="answer">
                                <p>{t("There are certain parameters that would unfortunately result in a disqualification of a potential customer from using our program. Some of these parameters include age, health conditions, glasses prescriptions that are out of reasonable bounds, and current ocular status.")}</p>
                            </div>
                        </div>
                        <div class="item">
                            <h4>{t("How much does it cost to take the test?")}</h4>
                            <div class="answer">
                                <p>{t("The INSTANT online prescription check is $30 and it does not require a doctor to review. The REVIEWED online prescription costs $50 and will take up to one day to process results.")}</p>
                            </div>
                        </div>
                        <div class="item">
                            <h4>{t("What do I need for this test?")}</h4>
                            <div class="answer">
                                <p>{t("A computer or laptop, a mobile phone, a credit card and 10ft of space.")}</p>
                            </div>
                        </div>
                        <div class="item">
                            <h4>{t("Does this program diagnose eye conditions?")}</h4>
                            <div class="answer">
                                <p>{t("No, the test is used as a way of measuring the powers that a potential pair of glasses or contacts would need for you to see well.")}</p>
                            </div>
                        </div>
                        <div class="item">
                            <h4>{t("What is your money-back guarantee policy?")}</h4>
                            <div class="answer">
                                <p>{t("If your prescription is confirmed incorrect by an eye doctor, send us the new prescription for a full refund. If the tests are inconclusive, we will also refund the amount paid.")}</p>
                            </div>
                        </div>
                        {/* <div class="item">
                            <h4>{t("Where can I order glasses & contact lenses?")}</h4>
                            <div class="answer">
                                <p>{t("Here is a link to the ecommerce sites:")}</p>
                            </div>
                        </div> */}
                    </div>
                    
                </section>
            </div>
            <a id="faq" name="faq"></a>
        </main>
    );
}

export default HomePage;
