import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function KnowOldSpecs() {
	const history = useHistory();
    const { t, i18n } = useTranslation();

	const qsName = "knowspec"; /*1. They know their glasses RX 
                               2. The know their glasses AND CL RX 
                               3. They don't know their glasses RX */

	const questionText = t("Do you know your current glasses prescription?");
	const questionText2 =
		t("(your glasses prescription will help us to better understand your test results)");
	const answerText1 = t("Yes");
	const answerText2 = t("No");

	const PATH_YES = "/specs";
	const PATH_NO = "/haveglasses";
	// const PATH_NO = "/knowoldcontacts";

	const buttonAction = (qsName, answer, path) => {
		if (answer == answerText1) {
			//set to 1 sinc ept has Spec Rx only
			localStorage.setItem("KnowSpec", "yes");
		}
		if (answer == answerText2) {
			//set to 1 sinc ept has Spec Rx only
			localStorage.setItem("KnowSpec", "no");
			localStorage.setItem("HaveSpec", "no");
		}

		localStorage.setItem("KnowContact", "no");
		history.push({
			pathname: path,
		});
	};

	return (
		<TestWrapper image={`images/test/004.jpg`}>
			<h2>{questionText}</h2>
			<h4>{questionText2}</h4>

			<div className="multiple-buttons">
				<div
					onClick={() => {
						buttonAction(qsName, answerText1, PATH_YES);
					}}
					className="button-default"
				>
					{answerText1}
				</div>

				<div
					onClick={() => {
						buttonAction(qsName, answerText2, PATH_NO);
					}}
					className="button-default"
				>
					<div>{answerText2}</div>
				</div>
			</div>
		</TestWrapper>
	);
}
