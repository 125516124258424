export function axis(p5) {

  let i = 0;
  let r = 0;
  let w = 1400;
  let h = 600;
  let stroker = 255;
  let strokeg = 150;
  let strokeb = 100;
  let strokea = 120;
  let dot_diam = 10;

  p5.setup = () => {p5.createCanvas(w, h);p5.rectMode(p5.CENTER);p5.angleMode(p5.DEGREES);}

  p5.updateWithProps = props => {
    if (props.r || props.i) {
      r = props.r;
      i = props.i;
    }
    console.log(i)
  };

  p5.draw = () => {
    p5.noStroke()
    p5.noSmooth()
    p5.background(255);
    p5.translate(w/2, h/2) // Leave room between both square

    p5.fill(0)
    p5.circle(0,0,dot_diam)

    p5.translate(-0.75*r-dot_diam/2, 0)

    p5.rotate(i)

    p5.fill(0,255,0)
    p5.rect(0,0,r,r)
    p5.strokeWeight(1)
    p5.stroke(stroker,strokeg,strokeb,strokea)
    p5.fill(220,0,0)
    p5.rect(0,0-r/10,r,r/10)
    p5.rect(0,0-3*r/10,r,r/10)  
    //rect(0,0-5*r/10,r,r/10)
    p5.rect(0,0+r/10,r,r/10)
    p5.rect(0,0+3*r/10,r,r/10) 
    //rect(0,0+5*r/10,r,r/10)

    p5.rotate(-i)
    // p5.translate(w/2, 0)
    p5.translate(2*0.75*r+dot_diam, 0)
    p5.noStroke()
    p5.rotate(i+90)
  
    p5.fill(0,255,0)
    p5.rect(0,0,r,r)
    p5.strokeWeight(1)
    p5.stroke(stroker,strokeg,strokeb,strokea)
    p5.fill(220,0,0)
    p5.rect(0,0-r/10,r,r/10)
    p5.rect(0,0-3*r/10,r,r/10)  
    //rect(0,0-5*r/10,r,r/10)
    p5.rect(0,0+r/10,r,r/10)
    p5.rect(0,0+3*r/10,r,r/10) 

    // p5.rotate(-1*(i+90))
    // p5.translate(-0.75*r, 0)
    // p5.fill(0)
    // p5.circle(0,0,15)
  }

}
