// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { Form } from 'react-bootstrap';
import './styles.css';
import TestWrapper from "../components/TestWrapper";


export default function Specs() {

    let history = useHistory();

    const [sr, setSr] = useState('');
    const [cr, setCr] = useState('');
    const [ar, setAr] = useState('');
    const [sl, setSl] = useState('');
    const [cl, setCl] = useState('');
    const [al, setAl] = useState('');
    const [spherePlus, setSpherePlus] = useState(false);

    useEffect(() => { 
        if (spherePlus === true) {
            SaveSpecs()
        }
    });

    const questionText = "Please enter your glasses prescription";

    // const [BCPlaceHolder, setBCPlaceHolder] = useState("Base Curve")

    const SaveSpecs = () => {
        if (sr.length === 0 && sl.length === 0) { alert("both sphere values must be entered") }
        else if (sr.length === 0) { alert("value for right eye sphere must be entered") }
        else if (sl.length === 0) { alert("value for left eye sphere must be entered") }
        else if (cr > 0 || cl > 0) { alert("Cylinder values must be negative numbers") }
        else if (ar.length > 3 || al.length > 3 || ar > 180 || al > 180 || ar < 0 || al < 0) { alert("Axis values have up to 3 digits (from 0 to 180)") }
        else if (cr < 0 && ar.length === 0) { alert("AXIS MISSING for right eye (must be between 0 and 180 if there is a cylinder value)") }
        else if (cl < 0 && al.length === 0) { alert("AXIS MISSING for left eye (must be between 0 and 180 if there is a cylinder value)") }
        else if ( (sr > 0 || sl > 0) && spherePlus === false) { 
            Swal.fire({
                title: 'Please confirm plus and not minus sphere?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                icon: "info",
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1',
                    confirmButton: 'order-2',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    setSpherePlus(true)
                    console.log( " depois de alterarspherePlus", spherePlus)
                }
            })
        }
        else {

            localStorage.setItem("OldSphOD", sr)
            localStorage.setItem("OldSphOS", sl)
            localStorage.setItem("OldCylOD", cr)
            localStorage.setItem("OldCylOS", cl)
            localStorage.setItem("OldAxisOD", ar)
            localStorage.setItem("OldAxisOS", al)

            if (cr.length === 0 || cr === 0) { localStorage.setItem("OldCylOD", 0) }
            if (cl.length === 0 || cl === 0) { localStorage.setItem("OldCylOS", 0) }
            if (ar.length === 0) { localStorage.setItem("OldAxisOD", 0) }
            if (al.length === 0) { localStorage.setItem("OldAxisOS", 0) }
            if (cr.length === 0 || cr === 0) { localStorage.setItem("OldAxisOD", "no axis") }
            if (cl.length === 0 || cl === 0) { localStorage.setItem("OldAxisOS", "no axis") }


            localStorage.setItem("KnowContact", "no");
            history.push({
                pathname: '/haveglasses',
                // pathname: '/knowoldcontacts',
            });
        }
    };


    return (
        <TestWrapper image={`images/test/005.jpg`}>
            <h2 className="mb-5">{questionText}</h2>

            <Form>

                <div className='specs-table'>
                    <h2 className="mr-4">{"Right (OD)"}</h2>

                    <div className="form-item">
                        <h5 >SPHERE</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setSr(v.target.value) }} type="number" placeholder="Sphere" size={'md'} step={0.25} />
                    </div>

                    <div className="form-item">
                        <h5 >CYLINDER</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setCr(v.target.value) }} type="number" placeholder="Cylinder" size={'md'} step={0.25} max={0} />
                    </div>

                    <div className="form-item">
                        <h5 >AXIS</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setAr(v.target.value) }} type="number" placeholder="AXIS" size={'md'} min={0} max={180} />
                    </div>

                </div>


                <div className='specs-table'>
                    <h2 className="mr-4">{"Left (OS)"}</h2>

                    <div className="form-item">
                        <h5 >SPHERE</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setSl(v.target.value) }} type="number" placeholder="Sphere" size={'md'} step={0.25} />
                    </div>

                    <div className="form-item">
                        <h5 >CYLINDER</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setCl(v.target.value) }} type="number" placeholder="Cylinder" size={'md'} step={0.25} max={0} />
                    </div>

                    <div className="form-item">
                        <h5 >AXIS</h5>
                        <Form.Control style={{ width: '90%' }} onChange={(v) => { setAl(v.target.value) }} type="number" placeholder="AXIS" size={'md'} min={0} max={180} />
                    </div>
                </div>

            </Form>


            <div className="nav-view">
                <div
                    onClick={SaveSpecs}
                    className="nav-button"
                >
                    <div>Continue</div>
                </div>
            </div>

        </TestWrapper>
    );
}