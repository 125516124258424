import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function Page4knowspec() {
	const history = useHistory();
     const { t, i18n } = useTranslation();

	const qsName = "knowspec"; /*1. They know their glasses RX 
                               2. The know their glasses AND CL RX 
                               3. They don't know their glasses RX */

	const questionText = t("Which of the following applies to you?");
	const answerText1 =
		t("I know my most recent glasses precription and I have my glasses");
	const answerText2 =
		t("I know my most recent glasses AND contact lens prescription AND I have my glasses");
	const answerText3 =
		t("I know my most recent glasses precription but I DON'T have my glasses");
	const answerText4 =
		t("I know my most recent glasses AND contact lens prescription but I DON'T have my glasses");
	const answerText5 = t("I don't know my glasses prescription");

	// const KNOW_SPECS_YES = 1;
	// const KNOW_SPECS_NO = 2;
	const PATH_YES = "/specs";
	const PATH_NO = "/placecard";
	const GLASSES = 1;
	const CONTACTS = 2;
	const UNKNOWN = 3;

	const TestCorrected_YES = 1; /////////IF TestCorrected = yes than the program should run through the refraction corrected
	const TestCorrected_NO = 2; /////////IF TestCorrected = yes than the program should only do an uncorrected refraction

	const buttonAction = (qsName, prescription, path, TestCorrected) => {
		if (prescription == GLASSES) {
			//set to 1 sinc ept has Spec Rx only
			localStorage.setItem("glasses", t("yuup I have glasses"));
		}
		if (prescription == CONTACTS) {
			// set to 2 if pt knows both Spec and CL Rx
		}
		if (prescription == UNKNOWN) {
			//set to 3 since pt doesn't know their glasses Rx
		}

		if (TestCorrected == TestCorrected_YES) {
			//TODO: Update this page to check if contacts or glasses
		} else {
		}

		history.push({
			pathname: path,
		});
	};

	return (
		<TestWrapper image={`images/test/003.jpg`}>
			<h2>{questionText}</h2>

			<div className="multiple-buttons">
				<div
					onClick={() => {
						buttonAction(qsName, GLASSES, PATH_YES, TestCorrected_YES);
					}}
					className="button-default"
				>
					<div>{answerText1}</div>
				</div>

				<div
					onClick={() => {
						buttonAction(qsName, CONTACTS, PATH_YES, TestCorrected_YES);
					}}
					className="button-default"
				>
					<div>{answerText2}</div>
				</div>

				<div
					onClick={() => {
						buttonAction(qsName, UNKNOWN, PATH_NO, TestCorrected_NO);
					}}
					className="button-default"
				>
					<div>{answerText3}</div>
				</div>
			</div>

			<div
				onClick={() => {
					buttonAction(qsName, GLASSES, PATH_NO, TestCorrected_NO);
				}}
				className="button-default"
			>
				<div>{answerText4}</div>
			</div>
			<div
				onClick={() => {
					buttonAction(qsName, UNKNOWN, PATH_NO, TestCorrected_NO);
				}}
				className="button-default"
			>
				<div>{answerText5}</div>
			</div>
		</TestWrapper>
	);
}

// page: know specs?
