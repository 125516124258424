//import './App.css';
import React, { Suspense, useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { ReactComponent as Check } from "../assets/images/check.svg";
import ErrorView from "../components/Error";

function App() {

	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const [state, setState] = useState({
		view: "loading",
	});

	const check = async () => {
		let params = new URLSearchParams(location.search);

		try {
			// First check if need to pay

			if (params.get("status") === "success" && params.get("session_id")) {
				let paymentUpdate = await axios
					.post(
						`${process.env.REACT_APP_API}/user/update-payment-session`,
						{ session_id: params.get("session_id") },
						{
							withCredentials: true,
						}
					)
					.then((res) => res?.data?.result);

				if (paymentUpdate) {
					setState({
						view: "success",
					});
				} else {
					setState({
						view: "error",
					});
				}
			} else {
				let paymentStatus = await axios
					.get(`${process.env.REACT_APP_API}/user/check-payment`, {
						withCredentials: true,
					})
					.then((res) => res?.data?.result);

				if (paymentStatus) {
					history.push(`/subscription`);
				} else {
					return setState({
						view: "pay",
					});
				}
			}
		} catch (error) {
			setState({
				view: "error",
			});
		}
	};

	useEffect(() => {
		check();
	}, []);

	const pay = () => {
		setState((s) => {
			return {
				...s,
				status: "loading",
			};
		});

		axios
			.post(
				`${process.env.REACT_APP_API}/user/create-payment-session`,
				{},
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				if (res?.data?.result) {
					window.location.href = res.data.url;
				}
			})
			.catch((e) => {
				let error = t("Could not process at this time. Please, try again later.");
				if (e.response.status === 400 && e.response?.data?.error) {
					error = e.response.data.error;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error,
				});
			});
	};

	return (
		<>
			{state.view === "loading" && (
				<main className="loading">
					<Spinner />
				</main>
			)}

			{state.view === "success" && (
				<main className="loading">
					<Check />
					<div className="loading-options">
						<h2>{t("Your subscription is now active")}</h2>
						<button
							onClick={() => {
								history.push(`/start`);
							}}
						>
							{t("Get started")}
						</button>
					</div>
				</main>
			)}

			{state.view === "pay" && (
				<main className="payment-not-ready">
					<div className="inset">
						<div className="images">
							<img src={"images/img1.jpg"} alt="" />
							<img src={"images/img2.jpg"} alt="" />
						</div>
						<h2>{t("Looks like your EyeTesters subscription is not active")}</h2>
						<span>
							{t("Get started with your online tests by subscribing today.")}
						</span>
						{state?.status === "loading" ? (
							<div className="loading-inline">
								<Spinner />
							</div>
						) : (
							<button onClick={pay}>{t("Subscribe now")}</button>
						)}
					</div>
				</main>
			)}
			{state.view === "error" && <ErrorView/>}
		</>
	);
}

export default App;
