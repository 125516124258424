
import {
    Q3_ORIENTATIONS,
    INITVA,
    MYOPIC,
    HYPEROPIC,
    MIXED,
    UNKNOWN,
    CHANGE_INTS,
    TD,
} from './constants';

export function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
}

export function getSize(va, scR, td=TD) {
    return scR * 5 * td * 100 * Math.tan((va / 1200) * Math.PI / 180);
}

// Axis coord sums -> angle
export function getAngle(axis_x, axis_y, wrongCount) {
    if (wrongCount == Q3_ORIENTATIONS.length) {
        console.log('no stigma')
        return 0
    }
    axis_x = axis_x/(Q3_ORIENTATIONS.length - wrongCount)
    axis_y = axis_y/(Q3_ORIENTATIONS.length - wrongCount)
    // alert("axis_x = "+ axis_x + " , axis_y = " + axis_y)
    let theta = ((Math.atan2(axis_y, axis_x) * 180 / Math.PI + 360) % 360) / 2
    // alert("Math.round(theta) =" + Math.round(theta))
    return Math.round(theta);
}

// or value -> actual orientation for p5
export function getOrientation(or, axis=-1) {
    let theta
    // default for test 2
    if (axis == -1) {
        theta = (or-1)*90
    // left side of screen test 4
    } else if (axis >= 0 && axis < 90) {
        theta = axis + (or-1)*180
    // right side of screen test 4
    } else if (axis >= 90 && axis < 180) {
        theta = axis + (or)*180
    } else {
        console.log('invalid axis: ' + axis)
    }

    return theta;
}

export function getSight(answer) {
    let sight;
    switch (answer) {
        case MYOPIC:
            sight = 'near';
        break;
        case HYPEROPIC:
            sight = 'far';
        break;
        case MIXED:
            sight = 'mixed';
        break;
        case UNKNOWN:
            sight = 'unknown';
        break; 
        default: 
            console.log('invalid sight answer: ' + answer);
        break;
    }
    return sight;
}

export const handleQuestion = (pageStatus, answer, or, va, runCount, wrongCount, changeInt, axis_x, axis_y) => {

    switch (pageStatus) {
        case 5: 
            var {or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect} = handleQ2(answer, or, va, runCount, wrongCount, changeInt, 4)
        break;

        case 6:
            var {or, va, runCount, wrongCount, axis_x, axis_y, nextTest, answerCorrect} = handleQ3(answer, or, va, runCount, wrongCount, axis_x, axis_y)
        break;

        case 7: // Use q2 but with only quadrant 2 options
            var {or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect} = handleQ2(answer, or, va, runCount, wrongCount, changeInt, 2)
        break;

        default: 
            console.log('invalid page: ' + pageStatus);
        break;
    }

    return { or, va, runCount, wrongCount, changeInt, axis_x, axis_y, nextTest, answerCorrect };
}

const handleQ2 = (answer, or, va, runCount, wrongCount, changeInt, quadrant) => {
    console.log('handleQ2...');
    let nextTest = false;
    let answerCorrect = false;

    if (answer === or && va > 5) {
        console.log('Correct!');
        answerCorrect = true;
        va = va - changeInt;
        if (va < 5) va=5;
    } else {
        console.log('Wrong!');
        // if (va > INITVA) {
        //     console.log("Out of Scope!");
        //     nextTest = true;
        //     wrongCount = CHANGE_INTS.length;
        //     runCount = 2;
        // } else {
        wrongCount = wrongCount + 1
        if (wrongCount == CHANGE_INTS.length) {
            va = va + changeInt
        } else {
            va = va + 1*changeInt // Go up 1 or 2 changeInts
            changeInt = CHANGE_INTS[wrongCount]
        }
        // }
    }

    if (wrongCount == CHANGE_INTS.length) {
        runCount = runCount+1;
        changeInt = CHANGE_INTS[0];
        wrongCount = 0;
        if (runCount == 1) {
            console.log("VA-Left: " + va);
            // let SPE = ((va + 18.542) / -83.5) // PUT IN PRESCRIPTION CREATOR
        } else {
            console.log("VA-Right: " + va);
            runCount = 0;
            or = 0;
            nextTest = true;
        }
    }

    if (nextTest == false) {
        or = getRandomInt(quadrant)
    }

    return { or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect };
};

// or means or_idx and wrongCount means skipped axes
const handleQ3 = (answer, or, va, runCount, wrongCount, axis_x, axis_y) => {
    console.log('handleQ3...');
    let nextTest = false;
    let answerCorrect = false;

    if (answer == 2 && va < 250) {
        va = va + 5;
        answerCorrect = true;
    } else if (answer == 4 && va > 10) {
        va = va - 5;
        answerCorrect = true;
    } else if (answer == 1 || answer == 3) {
        let theta = Q3_ORIENTATIONS[or]
        if (answer == 3) {
            theta = (theta + 90)
        }
        console.log("theta = ", theta)
        theta = theta * (Math.PI/90) // 90 b/c range from 0-180
        // neg cos is pos x axis, cancels out with final tan
        axis_x = axis_x + Math.cos(theta)
        axis_y = axis_y + Math.sin(theta)
        or = or + 1;
        answerCorrect = true;
    } else if (answer == 5) {
        wrongCount = wrongCount + 1;
        or = or + 1;
        //THIS IS A DEBUG SKIP
        //END OF DEBUG SKIP
    }

    if (or == Q3_ORIENTATIONS.length) {
        runCount = runCount+1;
        if (runCount == 1) {
            console.log("axis_x-Left: " + axis_x);
            console.log("axis_y-Left: " + axis_y);
            or = 0;
        } else {
            console.log("axis_x-Right: " + axis_x);
            console.log("axis_y-Right: " + axis_y);
            or = getRandomInt(2) //for q4
            runCount = 0;
            nextTest = true;
        }
    }

    return {or, va, runCount, wrongCount, axis_x, axis_y, nextTest, answerCorrect};
}
