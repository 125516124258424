// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import './styles.css';
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function Contacts() {
    let history = useHistory();
     const { t, i18n } = useTranslation();

    const [SrCl, setSrCl] = useState('');
    const [CrCl, setCrCl] = useState('');
    const [ArCl, setArCl] = useState('');
    const [BrandrCl, setBrandrCl] = useState('');
    const [BCrCl, setBCrCl] = useState('');
    const [DiamrCl, setDiamrCl] = useState('');

    const [SlCl, setSlCl] = useState('');
    const [ClCl, setClCl] = useState('');
    const [AlCl, setAlCl] = useState('');
    const [BrandlCl, setBrandlCl] = useState('');
    const [BClCl, setBClCl] = useState('');
    const [DiamlCl, setDiamlCl] = useState('');




    const questionText = t("Please enter your Contact Lens information");

    const [BCPlaceHolder, setBCPlaceHolder] = useState(t("Base Curve"))

    const Button1 = () => {

        if (SrCl.length == 0 || SlCl.length == 0) { alert(t("SPHERE MISSING (both sphere values must be entered")) }
        else if (CrCl.length + CrCl != 0 && ArCl.length + ArCl == 0) { alert(t("AXIS missing for right eye -- if Cylinder is present so must Axis ")) }
        else if (CrCl.length + CrCl == 0 && ArCl.length + ArCl != 0) { alert(t("Cylinder missing for right eye-- if Axis is present so must Cylinder ")) }
        else if (CrCl > 0 || ClCl > 0) { alert(t("Cylinder values must be negative")) }
        else if (BCrCl.length + BCrCl == 0 || DiamrCl.length + DiamrCl == 0) { alert(t("Base Curve or Diameter is missing for Right Eye")) }
        else if (BrandrCl == 0) { alert(t("Brand selection missing for right eye")) }
        else if (BCrCl > 9 || BCrCl < 8.2) { alert(t("Right Eye Base Curve incorrect (all values must be between 8.2 and 9.0")) }
        else if (DiamrCl > 14.5 || DiamrCl < 13.8) { alert(t("Right Eye Base Curve incorrect (all values must be between 13.8 and 14.5")) }
        else if (ClCl.length + ClCl != 0 && AlCl.length + AlCl == 0) { alert(t("AXIS missing for left eye -- if Cylinder is present so must Axis ")) }
        else if (ClCl.length + ClCl == 0 && AlCl.length + AlCl != 0) { alert(t("Cylinder missing for left eye-- if Axis is present so must Cylinder ")) }
        else if (BClCl.length + BClCl == 0 || DiamlCl.length + DiamlCl == 0) { alert(t("Base Curve or Diameter is missing for Left Eye")) }
        else if (BrandlCl == 0) { alert(t("Brand selection missing for left eye")) }
        else if (BClCl > 9 || BClCl < 8.2) { alert(t("Left Eye Base Curve incorrect (all values must be between 8.2 and 9.0")) }
        else if (DiamlCl > 14.5 || DiamlCl < 13.8) { alert(t("Left Eye Base Curve incorrect (all values must be between 13.8 and 14.5")) }
        else {


            localStorage.setItem("CL_OldSphOD", SrCl);
            localStorage.setItem("CL_OldCylOD", CrCl);
            localStorage.setItem("CL_OldAxisOD", ArCl);
            localStorage.setItem("CL_OldBrandOD", BrandrCl);
            localStorage.setItem("CL_OldBaseCurveOD", BCrCl);
            localStorage.setItem("CL_OldDiamOD", DiamrCl);

            localStorage.setItem("CL_OldSphOS", SlCl);
            localStorage.setItem("CL_OldCylOS", ClCl);
            localStorage.setItem("CL_OldAxisOS", AlCl);
            localStorage.setItem("CL_OldBrandOS", BrandlCl);
            localStorage.setItem("CL_OldBaseCurveOS", BClCl);
            localStorage.setItem("CL_OldDiamOS", DiamlCl);


            if (CrCl == 0 || ArCl == 0) {
                localStorage.setItem("CL_OldCylOD", 0);
                localStorage.setItem("CL_OldAxisOD", "no axis");
            }
            if (ClCl == 0 || AlCl == 0) {
                localStorage.setItem("CL_OldCylOS", 0);
                localStorage.setItem("CL_OldAxisOS", "no axis");
            }

            history.push({
                pathname: '/placecard'
            });
        }
    };


    return (
        <TestWrapper image={`images/test/007.jpg`}>
            <h2 className='mb-5'>{questionText}</h2>

            <Form>

                <div className='specs-table-lens'>
                    <h2>{t("Right (OD)")}</h2>

                    <div className="form-item">
                        <h5 >{t("SPHERE")}</h5>
                        <Form.Control onChange={(v) => { setSrCl(v.target.value) }} type="number" placeholder={t("Sphere")} size={'md'} step={0.25} />
                    </div>

                    <div className="form-item">
                        <h5>{t("CYLINDER")}</h5>
                        <Form.Control onChange={(v) => { setCrCl(v.target.value) }} type="number" placeholder={t("Cylinder")} size={'md'} step={0.25} max={0} />
                    </div>

                    <div className="form-item">
                        <h5>{t("AXIS")}</h5>
                        <Form.Control onChange={(v) => { setArCl(v.target.value) }} type="number" placeholder={t("AXIS")} size={'md'} min={0} max={180} />
                    </div>
                </div>



                <div className='specs-table-lens-brand'>
                    <Form.Group className='brand'>
                        <Form.Control as="select" value={BrandrCl} onChange={(v) => { setBrandrCl(v.target.value) }}>
                            <option value="0">{t("Lens Brand (Right Eye)")}</option>
                            <option value="1-Day Acuvue Define">1-Day Acuvue Define</option>
                            <option value="1-Day Acuvue Moist">1-Day Acuvue Moist</option>
                            <option value="1-Day Acuvue Moist for Astigmatism">1-Day Acuvue Moist for Astigmatism</option>
                            <option value="1-Day Acuvue Moist Multifocal">1-Day Acuvue Moist Multifocal</option>
                            <option value="1-Day Acuvue TruEye">1-Day Acuvue TruEye</option>
                            <option value="Acuvue 2">Acuvue 2</option>
                            <option value="Acuvue Oasys 1-Day for Astigmatism">Acuvue Oasys 1-Day for Astigmatism</option>
                            <option value="Acuvue Oasys 1-Day with HydraLuxe">Acuvue Oasys 1-Day with HydraLuxe</option>
                            <option value="Acuvue Oasys for Astigmatism">Acuvue Oasys for Astigmatism</option>
                            <option value="Acuvue Oasys for Presbyopia">Acuvue Oasys for Presbyopia</option>
                            <option value="Acuvue Oasys Multifocal">Acuvue Oasys Multifocal</option>
                            <option value="Acuvue Oasys with Hydraclear Plus">Acuvue Oasys with Hydraclear Plus</option>
                            <option value="Acuvue Oasys with Transitions">Acuvue Oasys with Transitions</option>
                            <option value="Acuvue Vita">Acuvue Vita</option>
                            <option value="Acuvue Vita for Astigmatism">Acuvue Vita for Astigmatism</option>
                            <option value="Air Optix Aqua">Air Optix Aqua</option>
                            <option value="Air Optix Aqua Multifocal">Air Optix Aqua Multifocal</option>
                            <option value="Air Optix Colors">Air Optix Colors</option>
                            <option value="Air Optix for Astigmatism">Air Optix for Astigmatism</option>
                            <option value="Air Optix Night and Day Aqua">Air Optix Night and Day Aqua</option>
                            <option value="Air Optix Plus HydraGlyde">Air Optix Plus HydraGlyde</option>
                            <option value="Air Optix Plus HydraGlyde for Astigmatism">Air Optix Plus HydraGlyde for Astigmatism</option>
                            <option value="Air Optix Plus HydraGlyde Multifocal">Air Optix Plus HydraGlyde Multifocal</option>
                            <option value="Avaira Vitality Sphere">Avaira Vitality Sphere</option>
                            <option value="Avaira Vitality Toric">Avaira Vitality Toric</option>
                            <option value="Biofinity">Biofinity</option>
                            <option value="Biofinity Energys">Biofinity Energys</option>
                            <option value="Biofinity Multifocal">Biofinity Multifocal</option>
                            <option value="Biofinity Toric">Biofinity Toric</option>
                            <option value="Biofinity Toric Multifocal">Biofinity Toric Multifocal</option>
                            <option value="Biofinity Toric XR">Biofinity Toric XR</option>
                            <option value="Biofinity XR">Biofinity XR</option>
                            <option value="Biomedics 55 Premier">Biomedics 55 Premier</option>
                            <option value="Biomedics Toric">Biomedics Toric</option>
                            <option value="Biotrue ONEday">Biotrue ONEday</option>
                            <option value="BioTrue ONEday for Astigmatism">BioTrue ONEday for Astigmatism</option>
                            <option value="Biotrue ONEday for Presbyopia">Biotrue ONEday for Presbyopia</option>
                            <option value="Clariti 1day">Clariti 1day</option>
                            <option value="Clariti 1day multifocal">Clariti 1day multifocal</option>
                            <option value="Clariti 1day toric">Clariti 1day toric</option>
                            <option value="Dailies AquaComfort Plus">Dailies AquaComfort Plus</option>
                            <option value="Dailies AquaComfort Plus Multifocal">Dailies AquaComfort Plus Multifocal</option>
                            <option value="Dailies AquaComfort Plus Toric">Dailies AquaComfort Plus Toric</option>
                            <option value="Dailies Colors">Dailies Colors</option>
                            <option value="Dailies Total One">Dailies Total One</option>
                            <option value="Dailies Total One for Astigmatism">Dailies Total One for Astigmatism</option>
                            <option value="Dailies Total One Multifocal">Dailies Total One Multifocal</option>
                            <option value="Focus Dailies Sphere">Focus Dailies Sphere</option>
                            <option value="FreshLook ColorBlends">FreshLook ColorBlends</option>
                            <option value="FreshLook Colors">FreshLook Colors</option>
                            <option value="FreshLook Dimensions">FreshLook Dimensions</option>
                            <option value="FreshLook Handling Tint">FreshLook Handling Tint</option>
                            <option value="Infuse One-Day">Infuse One-Day</option>
                            <option value="MiSight 1 day">MiSight 1 day</option>
                            <option value="MyDay">MyDay</option>
                            <option value="MyDay Multifocal">MyDay Multifocal</option>
                            <option value="MyDay Toric">MyDay Toric</option>
                            <option value="Precision 1">Precision 1</option>
                            <option value="Precision 1 For Astigmatism">Precision 1 For Astigmatism</option>
                            <option value="Proclear 1 Day">Proclear 1 Day</option>
                            <option value="Proclear 1 Day Multifocal">Proclear 1 Day Multifocal</option>
                            <option value="Proclear Multifocal">Proclear Multifocal</option>
                            <option value="Proclear Multifocal Toric">Proclear Multifocal Toric</option>
                            <option value="Proclear Multifocal XR">Proclear Multifocal XR</option>
                            <option value="Proclear Sphere">Proclear Sphere</option>
                            <option value="Proclear Toric">Proclear Toric</option>
                            <option value="Proclear Toric XR">Proclear Toric XR</option>
                            <option value="PureVision">PureVision</option>
                            <option value="PureVision2 For Astigmatism">PureVision2 For Astigmatism</option>
                            <option value="PureVision2 For Presbyopia">PureVision2 For Presbyopia</option>
                            <option value="PureVision 2 HD">PureVision 2 HD</option>
                            <option value="PureVision Multi-Focal">PureVision Multi-Focal</option>
                            <option value="Soflens 38">Soflens 38</option>
                            <option value="Soflens Daily Disposable">Soflens Daily Disposable</option>
                            <option value="Soflens Daily Disposable For Astigmatism">Soflens Daily Disposable For Astigmatism</option>
                            <option value="SofLens Multifocal">SofLens Multifocal</option>
                            <option value="SofLens Toric">SofLens Toric</option>
                            <option value="Total30">Total30</option>
                            <option value="Ultra for Astigmatism">Ultra for Astigmatism</option>
                            <option value="Ultra for Presbyopia">Ultra for Presbyopia</option>
                            <option value="Ultra Multifocal for Astigmatism">Ultra Multifocal for Astigmatism</option>
                            <option value="Ultra with MoistureSeal">Ultra with MoistureSeal</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control onChange={(v) => { setBCrCl(v.target.value) }} type="number" placeholder={t("Base Curve (Right Eye)")} size={'md'} min={8.3} max={9} step={0.1} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control onChange={(v) => { setDiamrCl(v.target.value) }} type="number" placeholder={t("Diameter (Right Eye)")} size={'md'} min={13.8} max={14.5} step={0.1} />
                    </Form.Group>
                </div>


                <div className="space"></div>

                <div className='specs-table-lens'>
                    <h2 className="mr-4 form-item">{"Left (OS)"}</h2>

                    <div className="form-item">
                        <h5 >{t("Sphere").toUpperCase()}</h5>
                        <Form.Control onChange={(v) => { setSlCl(v.target.value) }} type="number" placeholder={t("Sphere")} size={'md'} step={0.25} />
                    </div>

                    <div className="form-item">
                        <h5>{t("Cylinder").toUpperCase()}</h5>
                        <Form.Control onChange={(v) => { setClCl(v.target.value) }} type="number" placeholder={t("Cylinder")} size={'md'} step={0.25} max={0} />
                    </div>

                    <div className="form-item">
                        <h5>{t("AXIS")}</h5>
                        <Form.Control onChange={(v) => { setAlCl(v.target.value) }} type="number" placeholder={t("AXIS")} size={'md'} max={180} min={0} />
                    </div>

                </div>


                <div className='specs-table-lens-brand'>
                    <Form.Group className='brand'>
                        <Form.Control as="select" value={BrandlCl} onChange={(v) => { setBrandlCl(v.target.value) }}>
                            <option value="0">{t("Lens Brand (Left Eye)")}</option>
                            <option value="1-Day Acuvue Define">1-Day Acuvue Define</option>
                            <option value="1-Day Acuvue Moist">1-Day Acuvue Moist</option>
                            <option value="1-Day Acuvue Moist for Astigmatism">1-Day Acuvue Moist for Astigmatism</option>
                            <option value="1-Day Acuvue Moist Multifocal">1-Day Acuvue Moist Multifocal</option>
                            <option value="1-Day Acuvue TruEye">1-Day Acuvue TruEye</option>
                            <option value="Acuvue 2">Acuvue 2</option>
                            <option value="Acuvue Oasys 1-Day for Astigmatism">Acuvue Oasys 1-Day for Astigmatism</option>
                            <option value="Acuvue Oasys 1-Day with HydraLuxe">Acuvue Oasys 1-Day with HydraLuxe</option>
                            <option value="Acuvue Oasys for Astigmatism">Acuvue Oasys for Astigmatism</option>
                            <option value="Acuvue Oasys for Presbyopia">Acuvue Oasys for Presbyopia</option>
                            <option value="Acuvue Oasys Multifocal">Acuvue Oasys Multifocal</option>
                            <option value="Acuvue Oasys with Hydraclear Plus">Acuvue Oasys with Hydraclear Plus</option>
                            <option value="Acuvue Oasys with Transitions">Acuvue Oasys with Transitions</option>
                            <option value="Acuvue Vita">Acuvue Vita</option>
                            <option value="Acuvue Vita for Astigmatism">Acuvue Vita for Astigmatism</option>
                            <option value="Air Optix Aqua">Air Optix Aqua</option>
                            <option value="Air Optix Aqua Multifocal">Air Optix Aqua Multifocal</option>
                            <option value="Air Optix Colors">Air Optix Colors</option>
                            <option value="Air Optix for Astigmatism">Air Optix for Astigmatism</option>
                            <option value="Air Optix Night and Day Aqua">Air Optix Night and Day Aqua</option>
                            <option value="Air Optix Plus HydraGlyde">Air Optix Plus HydraGlyde</option>
                            <option value="Air Optix Plus HydraGlyde for Astigmatism">Air Optix Plus HydraGlyde for Astigmatism</option>
                            <option value="Air Optix Plus HydraGlyde Multifocal">Air Optix Plus HydraGlyde Multifocal</option>
                            <option value="Avaira Vitality Sphere">Avaira Vitality Sphere</option>
                            <option value="Avaira Vitality Toric">Avaira Vitality Toric</option>
                            <option value="Biofinity">Biofinity</option>
                            <option value="Biofinity Energys">Biofinity Energys</option>
                            <option value="Biofinity Multifocal">Biofinity Multifocal</option>
                            <option value="Biofinity Toric">Biofinity Toric</option>
                            <option value="Biofinity Toric Multifocal">Biofinity Toric Multifocal</option>
                            <option value="Biofinity Toric XR">Biofinity Toric XR</option>
                            <option value="Biofinity XR">Biofinity XR</option>
                            <option value="Biomedics 55 Premier">Biomedics 55 Premier</option>
                            <option value="Biomedics Toric">Biomedics Toric</option>
                            <option value="Biotrue ONEday">Biotrue ONEday</option>
                            <option value="BioTrue ONEday for Astigmatism">BioTrue ONEday for Astigmatism</option>
                            <option value="Biotrue ONEday for Presbyopia">Biotrue ONEday for Presbyopia</option>
                            <option value="Clariti 1day">Clariti 1day</option>
                            <option value="Clariti 1day multifocal">Clariti 1day multifocal</option>
                            <option value="Clariti 1day toric">Clariti 1day toric</option>
                            <option value="Dailies AquaComfort Plus">Dailies AquaComfort Plus</option>
                            <option value="Dailies AquaComfort Plus Multifocal">Dailies AquaComfort Plus Multifocal</option>
                            <option value="Dailies AquaComfort Plus Toric">Dailies AquaComfort Plus Toric</option>
                            <option value="Dailies Colors">Dailies Colors</option>
                            <option value="Dailies Total One">Dailies Total One</option>
                            <option value="Dailies Total One for Astigmatism">Dailies Total One for Astigmatism</option>
                            <option value="Dailies Total One Multifocal">Dailies Total One Multifocal</option>
                            <option value="Focus Dailies Sphere">Focus Dailies Sphere</option>
                            <option value="FreshLook ColorBlends">FreshLook ColorBlends</option>
                            <option value="FreshLook Colors">FreshLook Colors</option>
                            <option value="FreshLook Dimensions">FreshLook Dimensions</option>
                            <option value="FreshLook Handling Tint">FreshLook Handling Tint</option>
                            <option value="Infuse One-Day">Infuse One-Day</option>
                            <option value="MiSight 1 day">MiSight 1 day</option>
                            <option value="MyDay">MyDay</option>
                            <option value="MyDay Multifocal">MyDay Multifocal</option>
                            <option value="MyDay Toric">MyDay Toric</option>
                            <option value="Precision 1">Precision 1</option>
                            <option value="Precision 1 For Astigmatism">Precision 1 For Astigmatism</option>
                            <option value="Proclear 1 Day">Proclear 1 Day</option>
                            <option value="Proclear 1 Day Multifocal">Proclear 1 Day Multifocal</option>
                            <option value="Proclear Multifocal">Proclear Multifocal</option>
                            <option value="Proclear Multifocal Toric">Proclear Multifocal Toric</option>
                            <option value="Proclear Multifocal XR">Proclear Multifocal XR</option>
                            <option value="Proclear Sphere">Proclear Sphere</option>
                            <option value="Proclear Toric">Proclear Toric</option>
                            <option value="Proclear Toric XR">Proclear Toric XR</option>
                            <option value="PureVision">PureVision</option>
                            <option value="PureVision2 For Astigmatism">PureVision2 For Astigmatism</option>
                            <option value="PureVision2 For Presbyopia">PureVision2 For Presbyopia</option>
                            <option value="PureVision 2 HD">PureVision 2 HD</option>
                            <option value="PureVision Multi-Focal">PureVision Multi-Focal</option>
                            <option value="Soflens 38">Soflens 38</option>
                            <option value="Soflens Daily Disposable">Soflens Daily Disposable</option>
                            <option value="Soflens Daily Disposable For Astigmatism">Soflens Daily Disposable For Astigmatism</option>
                            <option value="SofLens Multifocal">SofLens Multifocal</option>
                            <option value="SofLens Toric">SofLens Toric</option>
                            <option value="Total30">Total30</option>
                            <option value="Ultra for Astigmatism">Ultra for Astigmatism</option>
                            <option value="Ultra for Presbyopia">Ultra for Presbyopia</option>
                            <option value="Ultra Multifocal for Astigmatism">Ultra Multifocal for Astigmatism</option>
                            <option value="Ultra with MoistureSeal">Ultra with MoistureSeal</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control onChange={(v) => { setBClCl(v.target.value) }} type="number" placeholder={t("Base Curve (Right Eye)")} size={'md'} min={8.3} max={9} step={0.1} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control onChange={(v) => { setDiamlCl(v.target.value) }} type="number" placeholder={t("Diameter (Right Eye)")} size={'md'} min={13.8} max={14.5} step={0.1} />
                    </Form.Group>
                </div>

            </Form>

            <div className="nav-view">
                <div
                    onClick={Button1}
                    className="nav-button"
                >
                    <div>{t("Submit")}</div>
                </div>
            </div>
        </TestWrapper>
    );
}