import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";

function PrivateRoute({ children }) {
	const [state, setState] = useState({
		status: "loading",
		path: null,
	});

	const location = useLocation();

	const checkAuth = async () => {
		axios
			.post(
				`${process.env.REACT_APP_API}/user/auth`,
				{ path: location.pathname },
				{
					withCredentials: true,
				}
			)
			.then(function (res) {
				console.log(res.data);
				if (res.data.result) {
					if (!res?.data?.path) {
						setState({
							status: "authorized",
						});
					} else {
						setState({
							status: "redirect",
							path: res.data.path,
						});
					}
				} else {
					setState({
						status: "redirect",
						path: res.data.path || '/home',
					});
				}
			})
			.catch((e) => {
				if (e?.response?.status === 401) {
					setState({
						status: "redirect",
						path: "/login",
					});
				} else {
					setState({
						status: "server_error",
					});
				}
			});
	};

	useEffect(() => {
		checkAuth();
	}, [location]);

	useEffect(() => {
		checkAuth();
	}, []);

	return (
		<Route
			render={(props) => {
				if (state.status === "server_error") {
					return <div>error</div>;
				}

				if (state.status === "redirect" && location.pathname !== state.path) {
					return (
						<Redirect
							to={{
								pathname: state.path,
								state: { from: props.location },
							}}
						/>
					);
				}

				if (state.status === "loading") {
					return (
						<div className="loading-view">
							<div className="spinner-wrapper">
								<Spinner/>
							</div>
						</div>
					);
				}

				return children;
			}}
		/>
	);
}

export default PrivateRoute;
