import { withRouter } from "react-router-dom";
import React, {useState, useEffect} from "react";
import qs from "query-string";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from 'react-i18next';
import AudioGuide from '../components/AudioGuide'

function Mpage12mconnectionAndStepback(props) {
	const [state, setState] = useState({ pageStatus: undefined });
	const { t, i18n } = useTranslation();

	useEffect(() => {
		fetch(
			URL_ROUTE_USER +
				"/updateStatus" +
				window.location.search +
				"&pageStatus=1"
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.pageStatus !== 1) {
					setState({ pageStatus: -1 });
				} else {
					setState({ pageStatus: data.pageStatus });
				}
			});
		i18n.changeLanguage(query.lang)
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const query = qs.parse(window.location.search);
	if (state.pageStatus === -1) return <h1 className="noSession">NO SESSION</h1>;

	const Button1 = () => {
		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/placeobject",
			search: searchString,
		});
		props.history.push({
			pathname: "/placeobject",
			search: searchString,
		});
	}; 
	
	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>
						{t("Now follow the instructions on computer and move 10 feet away from your computer screen.")}
					</h1>
					<div className="nav-view">
						<div className="nav-button" onClick={Button1}>
							{t("Confirm")}
						</div>
					</div>
					<h2>{t("Accuracy is important!")}</h2>
					<AudioGuide 
						lang={query.lang}
						sound="mpage12"
					/>
				</div>
			</div>
		</>
	);
}

export default withRouter(Mpage12mconnectionAndStepback);
