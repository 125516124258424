import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import axios from "axios";
import {
	FunctionAddPower,
	FunctionEyeValence,
	SPEMethods,
	FunctionMeasuredRx,
	FunctionWeightedRx,
	FunctionProcessedFinalRx,
} from "../intake/AJmath";
import { 	URL_ROUTE_USER } from "../utils/constants";

export default function VerifyView({ data }) {
  const [formVar, setFormVar] = useState({
    user_id: "",
    age: "",
    dob: "",
    name: "",
    gender: "",
    KnowSpec: "",
    HaveSpec: "",
    KnowContact: "",
    CL_OldBrandOD: "",
    CL_OldBrandOS: "",
    ratio: "",
    OldSphOD: "",
    OldSphOS: "",
    OldCylOD: "",
    OldCylOS: "",
    OldAxisOD: "",
    OldAxisOS: "",
    CL_OldSphOD: "",
    CL_OldCylOD: "",
    CL_OldAxisOD: "",
    CL_OldBaseCurveOD: "",
    CL_OldDiamOD: "",
    CL_OldSphOS: "",
    CL_OldCylOS: "",
    CL_OldAxisOS: "",
    CL_OldBaseCurveOS: "",
    CL_OldDiamOS: "",
    NumberOfSteps: "",
    Answer1a: "",
    Answer1b: "",
    VAccOD: "",
    VAccOS: "",
    VAscOD: "",
    VAscOS: "",
    PrimeDrPOVOD: "",
    PrimeDrPOVOS: "",
    VAaOD: "",
    VAaOS: "",
    Add: "",
    EyeValenceOD: "",
    EyeValenceOS: "",
    EyeValenceSafety: "",
    SPEscOD: "",
    SPEscOS: "",
    SPEaOD: "",
    SPEaOS: "",
    MeasuredSphOD: "",
    MeasuredSphOS: "",
    MeasuredCylOD: "",
    MeasuredCylOS: "",
    MeasuredAxisOD: "",
    MeasuredAxisOS: "",
    FinalSphOD: "",
    FinalCylOD: "",
    FinalAxisOD: "",
    FinalCLAxisOD: "",
    FinalSphOS: "",
    FinalCylOS: "",
    FinalAxisOS: "",
    FinalCLAxisOS: "",
    uuid: "",
});
  const [logTest, setLogTest] = useState({});

  const location = useLocation();
  data = location.state.data;
  // if (!data) return null;
  // console.log('data', data);
  const { user_id, uuid  } = data;
  delete data["_id"];
  // delete data["user_id"];
  delete data["phone"];
  // delete data["uuid"];
  delete data["createdAt"];
  delete data["updatedAt"];
  delete data["__v"];
  delete data["email"];
	const keysData = Object.keys(data);

	const ItemTable = (props) => (
		<div style={{fontSize: "12px", width: "145px", padding: "3px", margin: "2px 3px 2px 0", height: "40px", border: "1px solid #ccc", alignItems: "flex-start"}}>
      <strong>{props.name}:</strong><br />{props.value}
    </div>
	)

	const ItemLogTable = (props) => {
    const removePage = [5,6,7].includes(props.page);
    const resultColum = false;//!!props.results // deixar com a validacao do results.
    const pages = [];
    pages[3] = "Answer 1";
    pages[4] = "Answer 2";
    pages[5] = "Test1";
    pages[6] = "Test2";
    pages[7] = "Test3";
    // console.log(pages);
    const titlePage = `${pages[props.page]} - ${props.answerDesktop?.left ? 'Left' : 'Right'}`;
    return (
		<tr class="table-row">
      { !resultColum && (<td class="col1"> <strong>{removePage ? titlePage : pages[props.page]}</strong> </td> )}
      {/* { resultColum && (<td class="col1"></td>)} */}

      { !resultColum && props.page !== 6 && (<td class="col2"> {removePage && (props.answerDesktop?.answerCorrect ? 'Correct' : 'Incorrect')} </td> )}
      {/* { resultColum && (<td class="col1"></td>)} */}
      { !resultColum && props.page !== 6 && <td class="col3">{(props.answerDesktop?.va)}</td>}
      {/* { resultColum && (<td class="col1"></td>)} */}
      { !resultColum && props.page === 6 && (
        <td class="col3" style={{widt: "100%"}}>
          axisX:{props.answerDesktop?.axis_x} - axisY:{props.answerDesktop?.axis_y}
        </td>
      )}
      {/* remover a negacao para voltar ao normal  */}
      { !resultColum && (<td class="col4">
        {props.results?.value?.name} - {props?.results?.value?.value}
      </td>)}
      {/* { !resultColum && (<td class="col1"></td>)} */}
    </tr>
	)}

  const formula3 = (_gender, _age) => {
    // console.log('params', _gender, _age);
    // console.log('formVar', formVar);
		//ADD POWER
		const gender = _gender;
		const age = _age;

		const AddPwr = FunctionAddPower(gender, age);
    // console.log('AddPwr', AddPwr);
    setFormVar({ ...formVar, AddPwr: AddPwr })
		return AddPwr;
	};

  const formula4 = (KnowSpec,
    KnowContact,
    OldSphOD,
    OldSphOS,
    OldCylOD,
    OldCylOS,
    Answer1a,
    Answer1b,
    CL_OldSphOD,
    CL_OldSphOS,
    CL_OldCylOD,
    CL_OldCylOS) => {
		//Eye VALENCE

		const { EyeValenceOD, EyeValenceOS, EyeValenceSafety } = FunctionEyeValence(
			KnowSpec,
			KnowContact,
			OldSphOD,
			OldSphOS,
			OldCylOD,
			OldCylOS,
			Answer1a,
			Answer1b,
			CL_OldSphOD,
			CL_OldSphOS,
			CL_OldCylOD,
			CL_OldCylOS
		)

    // console.log('Eye VALENCE', EyeValenceOD, EyeValenceOS, EyeValenceSafety);
    setFormVar({ ...formVar, EyeValenceOD, EyeValenceOS, EyeValenceSafety })

	};

  const formula5 = (
    eyeValenceOD,
    eyeValenceOS,
    VAscOD,
    VAscOS,
    VAaOD,
    VAaOS,
    Age
  ) => {
		// SPE

		let SPEscOD = SPEMethods(eyeValenceOD, VAscOD, Age);
		let SPEscOS = SPEMethods(eyeValenceOS, VAscOS, Age);
		let SPEaOD = SPEMethods(eyeValenceOD, VAaOD, Age);
		let SPEaOS = SPEMethods(eyeValenceOS, VAaOS, Age);

    // console.log('SPE', SPEscOD, SPEscOS, SPEaOD, SPEaOS);
    setFormVar({ ...formVar, SPEscOD, SPEscOS, SPEaOD, SPEaOS })
	};

  const formula6 = (
    EyeValenceOD,
    EyeValenceOS,
    SPEscOD,
    SPEscOS,
    SPEaOD,
    SPEaOS,
    PrimeDrPOVOD,
    PrimeDrPOVOS
  ) => {
		//MEASURED Rx

		let measuredRxOD = FunctionMeasuredRx(
			Number(SPEscOD),
			Number(SPEaOD),
			PrimeDrPOVOD,
			EyeValenceOD
		);
		let measuredRxOS = FunctionMeasuredRx(
			Number(SPEscOS),
			Number(SPEaOS),
			PrimeDrPOVOS,
			EyeValenceOS
		);

    // console.log('MEASURED Rx',
    // measuredRxOD,
    // measuredRxOS
    // );

    setFormVar({ 
      ...formVar,
      MeasuredSphOD: (Math.round(measuredRxOD.Sphere * 4) / 4).toFixed(2),
      MeasuredSphOS: (Math.round(measuredRxOS.Sphere * 4) / 4).toFixed(2),
      MeasuredCylOD: (Math.round(measuredRxOD.Cylinder * 4) / 4).toFixed(2),
      MeasuredCylOS: (Math.round(measuredRxOS.Cylinder * 4) / 4).toFixed(2),
      MeasuredAxisOD: measuredRxOD.Axis,
      MeasuredAxisOS: measuredRxOS.Axis
    })

	};
  const formula7 = () => {
		//Weighted FinalRx
    // console.log('formVar', formVar);
    const defaultOld = null
		let VAccOD = formVar.VAccOD3 || defaultOld;
		let VAccOS = formVar.VAccOS3 || defaultOld;
		let OldSphOD = formVar.OldSphOD3 || defaultOld;
		let OldSphOS = formVar.OldSphOS3 || defaultOld;
		let OldCylOD = formVar.OldCylOD3 || defaultOld;
		let OldCylOS = formVar.OldCylOS3 || defaultOld;
		let OldAxisOD = formVar.OldAxisOD3 || defaultOld;
		let OldAxisOS = formVar.OldAxisOS3 || defaultOld;

		let MeasuredSphOD = Number(formVar.MeasuredSphOD3);
		let MeasuredSphOS = Number(formVar.MeasuredSphOS3);
		let MeasuredCylOD = Number(formVar.MeasuredCylOD3);
		let MeasuredCylOS = Number(formVar.MeasuredCylOS3);
		let MeasuredAxisOD = Number(formVar.MeasuredAxisOD3);
		let MeasuredAxisOS = Number(formVar.MeasuredAxisOS3);
    let HaveSpec = formVar.HaveSpec3

		let FinalRxOD = FunctionWeightedRx(
			VAccOD,
			OldSphOD,
			OldCylOD,
			OldAxisOD,
			MeasuredSphOD,
			MeasuredCylOD,
			MeasuredAxisOD,
      HaveSpec
		);
		let FinalRxOS = FunctionWeightedRx(
			VAccOS,
			OldSphOS,
			OldCylOS,
			OldAxisOS,
			MeasuredSphOS,
			MeasuredCylOS,
			MeasuredAxisOS,
      HaveSpec
		);

    let FinalCLAxisOD = Math.round(FinalRxOD.FinalAxis / 10) * 10 //rounds the contact lens Axis to the nearest 10 degrees
    let FinalCLAxisOS = Math.round(FinalRxOS.FinalAxis / 10) * 10

    // console.log('Weighted FinalRxOD', FinalRxOD);
    // console.log('Weighted FinalRxOS', FinalRxOS);
    

    setFormVar({ 
      ...formVar,
      FinalSphOD: FinalRxOD.FinalSph,
      FinalCylOD: FinalRxOD.FinalCyl,
      FinalAxisOD: FinalRxOD.FinalAxis,
      FinalSphOS: FinalRxOS.FinalSph,
      FinalCylOS: FinalRxOS.FinalCyl,
      FinalAxisOS: FinalRxOS.FinalAxis,
      FinalCLAxisOD,
      FinalCLAxisOS
    })
	};

  useEffect(() => {
    axios.get(URL_ROUTE_USER + "/getLogTest?uuid=" + uuid + "&user_id=" + user_id)
      .then((res) => {
        // console.log('GetLogTest', res.data);
        setLogTest({ ...res.data })
        setFormVar({ 
          ...data,
          AddPwr: data.Add,
          // EyeValenceOS: data.EyeValenceOS,
          // EyeValenceOD: data.EyeValenceOD,
          eyeValenceOS: data.EyeValenceOS,
          eyeValenceOD: data.EyeValenceOD,
          PrimeDrPOVOS2: data.PrimeDrPOVOS,
          PrimeDrPOVOD2: data.PrimeDrPOVOD,
          SPEaOS2: data.SPEaOS,
          SPEaOD2: data.SPEaOD,
          SPEscOS2: data.SPEscOS,
          SPEscOD2: data.SPEscOD,
          EyeValenceOS2: data.EyeValenceOS,
          EyeValenceOD2: data.EyeValenceOD,
          HaveSpec3: data.HaveSpec,
          MeasuredAxisOS3: data.MeasuredAxisOS,
          MeasuredAxisOD3: data.MeasuredAxisOD,
          MeasuredCylOS3: data.MeasuredCylOS,
          MeasuredCylOD3: data.MeasuredCylOD,
          MeasuredSphOS3: data.MeasuredSphOS,
          MeasuredSphOD3: data.MeasuredSphOD,
          OldAxisOS3: data.OldAxisOS,
          OldAxisOD3: data.OldAxisOD,
          OldCylOS3: data.OldCylOS,
          OldCylOD3: data.OldCylOD,
          OldSphOS3: data.OldSphOS,
          OldSphOD3: data.OldSphOD,
          VAccOS3: data.VAccOS,
          VAccOD3: data.VAccOD
        })


      }).catch((error) => { 
        console.log('error GetLogTest', error);
      });
  }, []);

	return (

    <div style={{display: "flex"}}>
      <div class="tableDiv" style={{ /*position: "fixed",*/ left: 0, display: "flex", flexWrap: "wrap", maxWidth: "50%", width: "100%", alignItems: "flex-start", alignContent: "flex-start" }}>
        <div class="tableData" style={{ display: "flex", flexWrap: "wrap"}}>
          { keysData.map((item, i) => item !== "user_id" && item !== "uuid" && <ItemTable name={item} value={data[item]} index={i} />) }
        </div>
        <div class="tableLog" style={{width: "90%"}}>
          <h2>Table log</h2>
          {/* {console.log('aaaa->>>', logTest)} */}
          <table class="responsive-table">
            <tr class="table-header">
              <td class="col1">Test - Eye</td>
              <td class="col2">Answer?</td>
              <td class="col3">Generate Value</td>
              <td class="col4">Results</td>
            </tr>
            { logTest?.logs?.records?.map((item, i) => <ItemLogTable {...item} />) }
          </table>
        </div>
      </div>

      <Form style={{/*marginLeft: "50%",*/borderLeft: "2px solid black", maxWidth: "50%", width: "100%" }}>
        <Form.Group style={{ margin:"50px 20px"}}>
          <h4>Test AddPwd (Formula3)</h4>
          <br />
          <label style={{marginTop: "10px"}}>gender</label>
          <Form.Control
            type="text"
            placeholder="gender"
            value={formVar.gender}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, gender: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>age</label>
          <Form.Control
            type="text"
            placeholder="age"
            value={formVar.age}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, age: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            style={{marginLeft: '60px', width: '100px', height: '40px', margin: "10px 10px 10px 0"}}
            variant="primary"
            type="button"
            onClick={() => formula3(formVar.gender, formVar.age)}
          >
            Submit
          </Button>
          <div className="nav-view" style={{borderTop: "1px solid black", padding: "10px"}}>
            result AddPwd: <strong style={{fontSize: "20px"}}>{formVar.AddPwr}</strong>
          </div>
        </Form.Group>
        <Form.Group style={{ margin:"50px 20px"}}>
          <h4>Eye VALENCE (Formula4)</h4>
           <label style={{marginTop: "10px"}}>KnowSpec</label>
          <Form.Control
            type="text"
            placeholder="KnowSpec"
            value={formVar.KnowSpec}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, KnowSpec: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>KnowContact</label>
          <Form.Control
            type="text"
            placeholder="KnowContact"
            value={formVar.KnowContact}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, KnowContact: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="OldSphOD"
            value={formVar.OldSphOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldSphOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="OldSphOS"
            value={formVar.OldSphOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldSphOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="OldCylOD"
            value={formVar.OldCylOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldCylOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="OldCylOS"
            value={formVar.OldCylOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldCylOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>Answer1a</label>
          <Form.Control
            type="text"
            placeholder="Answer1a"
            value={formVar.Answer1a}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, Answer1a: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>Answer1b</label>
          <Form.Control
            type="text"
            placeholder="Answer1b"
            value={formVar.Answer1b}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, Answer1b: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>CL_OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="CL_OldSphOD"
            value={formVar.CL_OldSphOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, CL_OldSphOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>CL_OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="CL_OldCylOD"
            value={formVar.CL_OldCylOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, CL_OldCylOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>CL_OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="CL_OldSphOS"
            value={formVar.CL_OldSphOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, CL_OldSphOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>CL_OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="CL_OldCylOS"
            value={formVar.CL_OldCylOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, CL_OldCylOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            style={{marginLeft: '60px', width: '100px', height: '40px', margin: "10px 10px 10px 0"}}
            variant="primary"
            type="button"
            onClick={() => formula4(
              formVar.KnowSpec,
              formVar.KnowContact,
              formVar.OldSphOD,
              formVar.OldSphOS,
              formVar.OldCylOD,
              formVar.OldCylOS,
              formVar.Answer1a,
              formVar.Answer1b,
              formVar.CL_OldSphOD,
              formVar.CL_OldSphOS,
              formVar.CL_OldCylOD,
              formVar.CL_OldCylOS
              )}
          >
            Submit
          </Button>
          <div className="nav-view"  style={{borderTop: "1px solid black", padding: "10px"}}>
            result EyeValenceOD: <strong style={{fontSize: "20px"}}>{formVar.EyeValenceOD}</strong> <br />
            result EyeValenceOS: <strong style={{fontSize: "20px"}}>{formVar.EyeValenceOS}</strong> <br />
            result EyeValenceSafety: <strong style={{fontSize: "20px"}}>{formVar.EyeValenceSafety}</strong> <br />
          </div>
        </Form.Group>

        <Form.Group style={{ margin:"50px 20px"}}>
          <h4>SPE (Formula5)</h4>
          <br />
          <label style={{marginTop: "10px"}}>eyeValenceOD</label>
          <Form.Control
            type="text"
            placeholder="eyeValenceOD"
            value={formVar.eyeValenceOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, eyeValenceOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>eyeValenceOS</label>
          <Form.Control
            type="text"
            placeholder="eyeValenceOS"
            value={formVar.eyeValenceOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, eyeValenceOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>VAscOD</label>
          <Form.Control
            type="text"
            placeholder="VAscOD"
            value={formVar.VAscOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAscOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>VAscOS</label>
          <Form.Control
            type="text"
            placeholder="VAscOS"
            value={formVar.VAscOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAscOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>VAaOD</label>
          <Form.Control
            type="text"
            placeholder="VAaOD"
            value={formVar.VAaOD}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAaOD: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>VAaOS</label>
          <Form.Control
            type="text"
            placeholder="VAaOS"
            value={formVar.VAaOS}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAaOS: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>age</label>
          <Form.Control
            type="text"
            placeholder="age"
            value={formVar.age}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, age: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            style={{marginLeft: '60px', width: '100px', height: '40px', margin: "10px 10px 10px 0"}}
            variant="primary"
            type="button"
            onClick={() => formula5(
              formVar.eyeValenceOD,
              formVar.eyeValenceOS,
              formVar.VAscOD,
              formVar.VAscOS,
              formVar.VAaOD,
              formVar.VAaOS,
              formVar.age
              )}
          >
            Submit
          </Button>
          <div className="nav-view" style={{borderTop: "1px solid black", padding: "10px"}}>
            result SPEscOD: <strong style={{fontSize: "20px"}}>{formVar.SPEscOD}</strong> <br />
            result SPEscOS: <strong style={{fontSize: "20px"}}>{formVar.SPEscOS}</strong> <br />
            result SPEaOD: <strong style={{fontSize: "20px"}}>{formVar.SPEaOD}</strong> <br />
            result SPEaOS: <strong style={{fontSize: "20px"}}>{formVar.SPEaOS}</strong> <br />
          </div>
        </Form.Group>
        
        <Form.Group style={{ margin:"50px 20px"}}>
          <h4>MEASURED Rx (Formula6)</h4>
          <br />
          <label style={{marginTop: "10px"}}>EyeValenceOD</label>
          <Form.Control
            type="text"
            placeholder="EyeValenceOD"
            value={formVar.EyeValenceOD2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, EyeValenceOD2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>EyeValenceOS</label>
          <Form.Control
            type="text"
            placeholder="EyeValenceOS"
            value={formVar.EyeValenceOS2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, EyeValenceOS2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>SPEscOD</label>
          <Form.Control
            type="text"
            placeholder="SPEscOD"
            value={formVar.SPEscOD2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, SPEscOD2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>SPEscOS</label>
          <Form.Control
            type="text"
            placeholder="SPEscOS"
            value={formVar.SPEscOS2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, SPEscOS2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>SPEaOD</label>
          <Form.Control
            type="text"
            placeholder="SPEaOD"
            value={formVar.SPEaOD2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, SPEaOD2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>SPEaOS</label>
          <Form.Control
            type="text"
            placeholder="SPEaOS"
            value={formVar.SPEaOS2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, SPEaOS2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>PrimeDrPOVOD</label>
          <Form.Control
            type="text"
            placeholder="PrimeDrPOVOD"
            value={formVar.PrimeDrPOVOD2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, PrimeDrPOVOD2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>PrimeDrPOVOS</label>
          <Form.Control
            type="text"
            placeholder="PrimeDrPOVOS"
            value={formVar.PrimeDrPOVOS2}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, PrimeDrPOVOS2: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            style={{marginLeft: '60px', width: '100px', height: '40px', margin: "10px 10px 10px 0"}}
            variant="primary"
            type="button"
            onClick={() => formula6(
              formVar.EyeValenceOD2,
              formVar.EyeValenceOS2,
              formVar.SPEscOD2,
              formVar.SPEscOS2,
              formVar.SPEaOD2,
              formVar.SPEaOS2,
              formVar.PrimeDrPOVOD2,
              formVar.PrimeDrPOVOS2
              )}
          >
            Submit
          </Button>
          <div className="nav-view" style={{borderTop: "1px solid black", padding: "10px"}}>
            result MeasuredSphOD: <strong style={{fontSize: "20px"}}>{formVar.MeasuredSphOD}</strong> <br />
            result MeasuredSphOS: <strong style={{fontSize: "20px"}}>{formVar.MeasuredSphOS}</strong> <br />
            result MeasuredCylOD: <strong style={{fontSize: "20px"}}>{formVar.MeasuredCylOD}</strong> <br />
            result MeasuredCylOS: <strong style={{fontSize: "20px"}}>{formVar.MeasuredCylOS}</strong> <br />
            result MeasuredAxisOD: <strong style={{fontSize: "20px"}}>{formVar.MeasuredAxisOD}</strong> <br />
            result MeasuredAxisOS: <strong style={{fontSize: "20px"}}>{formVar.MeasuredAxisOS}</strong> <br />
          </div>
        </Form.Group>

        <Form.Group style={{ margin:"50px 20px"}}>
          <h4>Weighted FinalRx (Formula7)</h4>
          <br />
          <label style={{marginTop: "10px"}}>VAccOD</label>
          <Form.Control
            type="text"
            placeholder="VAccOD"
            value={formVar.VAccOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAccOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>VAccOS</label>
          <Form.Control
            type="text"
            placeholder="VAccOS"
            value={formVar.VAccOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, VAccOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="OldSphOD"
            value={formVar.OldSphOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldSphOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="OldSphOS"
            value={formVar.OldSphOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldSphOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="OldCylOD"
            value={formVar.OldCylOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldCylOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="OldCylOS"
            value={formVar.OldCylOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldCylOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldAxisOD</label>
          <Form.Control
            type="text"
            placeholder="OldAxisOD"
            value={formVar.OldAxisOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldAxisOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>OldAxisOS</label>
          <Form.Control
            type="text"
            placeholder="OldAxisOS"
            value={formVar.OldAxisOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, OldAxisOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredSphOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredSphOD"
            value={formVar.MeasuredSphOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredSphOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredSphOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredSphOS"
            value={formVar.MeasuredSphOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredSphOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredCylOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredCylOD"
            value={formVar.MeasuredCylOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredCylOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredCylOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredCylOS"
            value={formVar.MeasuredCylOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredCylOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredAxisOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredAxisOD"
            value={formVar.MeasuredAxisOD3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredAxisOD3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>MeasuredAxisOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredAxisOS"
            value={formVar.MeasuredAxisOS3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, MeasuredAxisOS3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <label style={{marginTop: "10px"}}>HaveSpec</label>
          <Form.Control
            type="text"
            placeholder="HaveSpec"
            value={formVar.HaveSpec3}
            size={'sm'}
            onChange={(v) => { setFormVar({ ...formVar, HaveSpec3: v.target.value }) }}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            style={{marginLeft: '60px', width: '100px', height: '40px', margin: "10px 10px 10px 0"}}
            variant="primary"
            type="button"
            onClick={() => formula7()}
          >
            Submit
          </Button>
          <div className="nav-view" style={{borderTop: "1px solid black", padding: "10px"}}>
            result FinalSphOD: <strong style={{fontSize: "20px"}}>{formVar.FinalSphOD}</strong> <br />
            result FinalCylOD: <strong style={{fontSize: "20px"}}>{formVar.FinalCylOD}</strong> <br />
            result FinalAxisOD: <strong style={{fontSize: "20px"}}>{formVar.FinalAxisOD}</strong> <br />
            result FinalCLAxisOD: <strong style={{fontSize: "20px"}}>{formVar.FinalAxisOD}</strong> <br />
            result FinalSphOS: <strong style={{fontSize: "20px"}}>{formVar.FinalSphOS}</strong> <br />
            result FinalCylOS: <strong style={{fontSize: "20px"}}>{formVar.FinalCylOS}</strong> <br />
            result FinalAxisOS: <strong style={{fontSize: "20px"}}>{formVar.FinalAxisOS}</strong> <br />
            result FinalCLAxisOS: <strong style={{fontSize: "20px"}}>{formVar.FinalCLAxisOS}</strong> <br />
          </div>
        </Form.Group>
      </Form>
    </div>
	);
}
