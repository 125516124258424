export function c_button(p5) {

  let i = 0;
  let r = 0;
  let w = 100;

  p5.setup = () => {p5.createCanvas(w, w);p5.rectMode(p5.CENTER);p5.angleMode(p5.DEGREES);}

  p5.updateWithProps = props => {

    if (props.r || props.i) {
      r = props.r;
      i = props.i;
    }

  };

  p5.draw = () => {
    p5.translate(w/2, w/2)

    p5.background(255)
    p5.noSmooth()
    
    p5.strokeWeight(1)
    p5.stroke(50,100)
    
    p5.fill(0)
    p5.circle(0,0,r)

    p5.fill(255)
    p5.circle(0,0,r*0.6)

    p5.rotate(i)
    
    p5.stroke(255,200)
    p5.rect(-r/3,0,r,r/5)
  }
}
