//import './App.css';
import React, { Suspense, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import LoginLogo from "../assets/images/login-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

function App() {
	

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						order
					</div>
				</div>
			</main>
		</>
	);
}

export default App;
