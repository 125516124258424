import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function Page8wearing() {
	let history = useHistory();
    const { t, i18n } = useTranslation();

	const qsName = "wearing";
	const questionText =
		t("For the next section you will need a mobile device (tablet/smartphone)");
	const answerText1 = t("Glasses");
	const answerText2 = t("Contacts");
	const answerText3 = t("None");
	const WEARING_YES = 1;
	const PATH_WEARING_YES = "/takeoffglasses";
	const PATH_WEARING_LENSES_YES = "/takeoffglasses";
	const WEARING_NO = 2;
	const BEGIN = "/connection";

	const buttonAction = () => {
		history.push({
			pathname: BEGIN,
		});
	};
	return (
		<TestWrapper image={`images/test/009.jpg`}>
			<h2>{questionText}</h2>
			<div className="answerArea">
				<div className="nav-view">
					<div onClick={() => buttonAction()} className="nav-button">
						<div>{t("Begin!")}</div>
					</div>
				</div>
				{/* <div onClick={() => buttonAction(qsName, WEARING_YES, PATH_WEARING_YES)} className='button'>
                    <div>{answerText1}</div>
                </div>
                <div onClick={() => buttonAction(qsName, WEARING_LENSES_YES, PATH_WEARING_LENSES_YES)}
                     className='button'>
                    <div>{answerText2}</div>
                </div>
                <div onClick={() => buttonAction(qsName, WEARING_NO, PATH_WEARING_NO)} className='button'>
                    <div>{answerText3}</div>
                </div> */}
			</div>
		</TestWrapper>
	);
}
