import React, { Suspense, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import HomePage from "./HomePage";
import Footer from "./Footer";
import Page1Agree from "./intake/page1agree";
import Page2Age from "./intake/page2age";
import Page3Gender from "./intake/page3gender";
import Page4knowspec from "./intake/page4knowspec";
import Page6placecard from "./intake/page6placecard";
import Page7shoesize from "./intake/page7shoesize";
import Page8wearing from "./intake/page8wearing";
import Page9TakeOffGlasses from "./intake/page9takeoff";
import Page11QR from "./intake/page11QR";
import Page12StartSession from "./intake/page12startsession";
import Page18Thankyou from "./intake/page18Thankyou";
import UserInfo from "./intake/userInfo";

import KnowOldSpecs from "./intake/AJknowoldspecs";
import Specs from "./intake/AJspecs";
import KnowOldContacts from "./intake/AJknowoldcontacts";
import Contacts from "./intake/AJcontacts";
import HaveGlasses from "./intake/AJhaveglasses";

import MPage12Connection from "./mobile/mpage12mconnectionAndStepback";
import MPagePlaceObject from "./mobile/mpage13placeobject";
import MPageTest1A from "./mobile/mpage14Test1ASimpleAnswer";
import MPageTest1B from "./mobile/mpage14Test1B";
import MPageTests from "./mobile/mpage15Tests";
import MPageEndOfTest from "./mobile/mpage16end";

import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import Payment from "./routes/Payment";
import Order from "./routes/Order";
import Profile from "./routes/Profile";
import Subscription from "./routes/Subscription";
import SubscriptionTrial from "./routes/SubscriptionTrial";
import Tests from "./routes/Tests";
import PrivacyPolicy from "./routes/PrivacyPolicy";

import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header";
import VerifyView from "./components/VerifyView.js";
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react';
import ForgottenPassword from "./routes/ForgottenPassword";
import ResetPassword from "./routes/ResetPassword";
import EmailValidation from "./routes/EmailValidation";
import Feedback from "./routes/Feedback";


if(window.location.host.includes('eyetesters.com')) {
	LogRocket.init('9d0idg/eyetesters-test');
	setupLogRocketReact(LogRocket);
}


function App() {
	const [user, setUser] = useState(null);
	const [status, setStatus] = useState("loading");

	useEffect(() => {
		TagManager.initialize({
			gtmId: "G-R6JZLWZ0DY",
		}); 
		initializeLogRocket();
	}, []);

	const initializeLogRocket = async () => {
		await axios.get(`${process.env.REACT_APP_API}/user/user-info`, { withCredentials: true })
			.then(function (res) {
				if (res.data.result) {
					setStatus("done");
					setUser(res.data.user);
					if(window.location.host.includes('eyetesters.com'))
						LogRocket.identify(res.data.user.code, {
							name: res.data.user.email,
							email: `${res.data.user.name} ${res.data.user.lastName}`,
						});
				}
			}).catch((e) => {
				setStatus("done");
			});
	}

	const Loader = () => (
		<div className="App">
			<div></div>
		</div>
	);

	return (
		<Suspense fallback={<Loader />}>
			<Router>
				<div className="container">
					<div className="main-content">
						<Switch>
							<Route exact path="/">
								<Redirect to="/home" />
							</Route>
							<Route exact path="/privacy-policy">
								<PrivacyPolicy/>
							</Route>
							<Route path="/login">
								<Login />
							</Route>
							<Route path="/signup">
								<SignUp />
							</Route>
							<Route path="/feedback">
								<Feedback />
							</Route>
							<Route path="/email-validation">
								<EmailValidation />
							</Route>
							<Route path="/forgotten">
								<ForgottenPassword />
							</Route>
							<Route path="/change_password/:token">
								<ResetPassword />
							</Route>
							<Route path="/home">
								<Header />
								<HomePage user={user} statusLoading={status} />
								<Footer />
							</Route>
							<Route path="/startsession">
								<Page12StartSession />
							</Route>

							<Route path="/mconnection">
								<MPage12Connection />
							</Route>
							<Route path="/placeobject">
								<MPagePlaceObject />
							</Route>
							<Route path="/mtest1A">
								<MPageTest1A />
							</Route>
							<Route path="/mtest1B">
								<MPageTest1B />
							</Route>
							<Route path="/mtests">
								<MPageTests />
							</Route>
							<Route path="/mend">
								<Header />
								<MPageEndOfTest />
								<Footer />
							</Route>
							<PrivateRoute>
								<Route path="/profile">
									<Profile />
								</Route>
								<Route path="/tests">
									<Tests />
								</Route>
								<Route path="/verify">
									<VerifyView />
								</Route>
								<Route path="/subscription">
									<Subscription />
								</Route>
								<Route path="/subscription-trial">
									<SubscriptionTrial />
								</Route>
								<Route path="/payment">
									<Payment />
								</Route>
								<Route path="/order">
									<Order />
								</Route>
								<Route path="/start">
									<Page1Agree user={user} statusLoading={status} />
								</Route>
								<Route path="/info">
									<UserInfo />
								</Route>
								<Route path="/age">
									<Page2Age />
								</Route>
								<Route path="/gender">
									<Page3Gender />
								</Route>
								<Route path="/knowoldspecs">
									<KnowOldSpecs />
								</Route>
								<Route path="/knowspec">
									<Page4knowspec />
								</Route>
								<Route path="/specs">
									<Specs />
								</Route>
								<Route path="/knowoldcontacts">
									<KnowOldContacts />
								</Route>
								<Route path="/Contacts">
									<Contacts />
								</Route>
								<Route path="/haveglasses">
									<HaveGlasses />
								</Route>
								<Route path="/placecard">
									<Page6placecard />
								</Route>
								<Route path="/shoesize">
									<Page7shoesize />
								</Route>
								<Route path="/wearing">
									<Page8wearing />
								</Route>
								<Route path="/takeoffglasses">
									<Page9TakeOffGlasses />
								</Route>
								<Route path="/connection">
									<Page11QR />
								</Route>
								<Route path="/thankyou">
									<Header />
									<Page18Thankyou />
									<Footer />
								</Route>
							</PrivateRoute>
						</Switch>
					</div>
				</div>
			</Router>
		</Suspense>
	);
}

export default App;
