// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function Page5TakeOffGlasses() {
	let history = useHistory();
	const { t, i18n } = useTranslation();

	const qsName = "takeoffglasses";
	const questionText = t("Please remove your glasses / contacts");

	const answerText1 = t("OK");
	const TAKE_OFF_YES = 1;
	const PATH_YES = "/connection";

	const buttonAction = (qsName, val, path) => {
		// history.push({
		//     pathname: path,
		// });
		history.goBack();
	};
	return (
		<div className="frame">
			<h2>{questionText}</h2>
			<div className="answerArea">
				<div
					onClick={() => buttonAction(qsName, TAKE_OFF_YES, PATH_YES)}
					className="button"
				>
					<div>{answerText1}</div>
				</div>
			</div>
		</div>
	);
}

//page: takeoff glasses
