export function big_c(p5) {

  let i = 0; // Angle in pi/2 rads (90deg)
  let r = 0; // length of drawing edges
  let w = 500; // width of canvas
  let c = true; // gray cover

  p5.setup = () => {p5.createCanvas(w, w);p5.rectMode(p5.CENTER);p5.angleMode(p5.DEGREES);} //, p5.WEBGL

  p5.updateWithProps = props => {

    if (props.r || props.i) {
      r = props.r;
      i = props.i;
      c = props.c;
    }
    
  };

  p5.draw = () => {
    p5.translate(w/2, w/2)

    p5.background(255)
    p5.noSmooth()

    if (r < 20) { // This helps make it look like a perfect circle MEASURE TO MAKE SURE!!!!!
      p5.strokeWeight(0)
    } else {
      p5.strokeWeight(1)
    }

    if (!c) {
      p5.stroke(50,100)
      p5.fill(0)
      p5.circle(0,0,r)

      p5.fill(255)
      p5.circle(0,0,r*0.6)

      p5.rotate(i) //(i-1)*90)

      // p5.stroke(255,200)
      p5.strokeWeight(0)
      p5.rect(-r/3,0,r,r/5) //see notes above but R is the length of the one WHOLE Snellen Letter -- this means r/5 is ONE optoype within a snellen letter
    } else {
      p5.stroke(150,100)
      p5.fill(150)
      p5.circle(0,0,r)

      p5.fill(255)
      p5.circle(0,0,r*0.6)
    }
  }
}
