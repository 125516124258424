import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from "react-i18next";

export const Checkbox = () => {
	const [isChecked, setIsChecked] = useState(false);
	const { t, i18n } = useTranslation();

	const checkHandler = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div>
			<input
				type="checkbox"
				id="checkbox"
				checked={isChecked}
				onChange={checkHandler}
			/>
			<label htmlFor="checkbox">{t("I agree to Terms of Service")} </label>
			<p>The checkbox is {isChecked ? "checked" : "unchecked"}</p>
		</div>
	);
};

export default function Page1Agree({ user, statusLoading }: any) {
	let history = useHistory();
  const { t, i18n } = useTranslation();
	localStorage.clear();
	const buttonAction = (qsName, val, path) => {
		history.push({
			pathname: path,
		});
	};

	const qsName = "agree";
	const questionText =
		t("Do you understand that this online test is not a replacement for an eye exam?");
	const answerText1 = t("Yes");
	const AGREE_YES = 1;
	const PATH_AGREE_YES = "/age";

	const verifyValidEmail = () => {
		if (!user?.email_valid) {
			history.push("/");
		}
		return
	}

	verifyValidEmail();

	return (
		<TestWrapper image={`images/test/001.jpg`}>
			<div className="agree-view">
				<h2>{questionText}</h2>

				<div className="nav-view">
					<div
						onClick={() => buttonAction(qsName, AGREE_YES, PATH_AGREE_YES)}
						className="nav-button"
					>
						<div>{answerText1}</div>
					</div>
				</div>
			</div>
		</TestWrapper>
	);
}
