// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { withRouter } from "react-router-dom";
import React from "react";
import qs from "query-string";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import "./styles.css";
import { withTranslation, useTranslation } from "react-i18next";
import {
	PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK,
	PAGE_STATUS_PLACING_OBJECT,
	PAGE_STATUS_PLACING_TEST1A,
	PAGE_STATUS_PLACING_TEST1B,
	PAGE_STATUS_PLACING_TEST2,
	PAGE_STATUS_PLACING_TEST3,
	PAGE_STATUS_PLACING_TEST4,
	POLLING_INTERVAL,
	ROUTES_PC_STARTED_SESSION,
	URL_ROUTE_USER,
	Q_ARR,
	Q3_ORIENTATIONS,
	INITVA,
	CHANGE_INTS,
	VA_MULTIPLIER,
	MAX_AXIS_VA,
} from "../utils/constants";
import { getQuery } from "../utils";
import nearFar from "../assets/img/nearfar.jpg";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { big_c } from "../sketches/big_c";
import { axis } from "../sketches/axis_2";
import {
	handleQuestion,
	getRandomInt,
	getSize,
	getAngle,
	getOrientation,
	getSight,
} from "../utils/core";
import cartoonOne from "../assets/img/cartoon1.svg";
import cartoonTwo from "../assets/img/cartoon2.svg";
import cartoonBlank from "../assets/img/cartoonBLANK.jpeg";
import cartoonLeft from "../assets/img/cartoonCoverLeftEye.svg";
import cartoonRight from "../assets/img/cartoonCoverRightEye.svg";
import cartoonLeftGlass from "../assets/img/cartoonCoverLeftEyeGlasses.svg";
import cartoonRightGlass from "../assets/img/cartoonCoverRightEyeGlasses.svg";

class Page12StartSession extends React.Component {
	state = {
		head: this.props.t("Stage Complete!"),
		subhead: this.props.t("Cover your LEFT eye then press 'Next Stage'"),
		nextTest: true,
		pageStatus: 0,
		answer: 0,
		flag: 0,
		cover: false,
		q_idx: 0,
		wearing: false,
		scR: localStorage.getItem("ratio"),

		// Intermediate variables
		or: getRandomInt(4),
		va: INITVA,
		changeInt: CHANGE_INTS[0],
		runCount: 0,
		wrongCount: 0,
		left: false,
		axis_x: 0,
		axis_y: 0,
		finalized: false,
	};

	updateTestRecord(token, data, type = "answerDesktop") {
		let typeURL = "";
		if (type === "answerDesktop") {
			typeURL = "/updateTestRecords";
		} else {
			typeURL = "/updateTestRecordsResult";
		}
		if (this.state.finalized === true) return;
		axios.post( URL_ROUTE_USER + `${typeURL}/${type}/${this.state.uuid}`, { ...data },
			{ headers: {"Content-type": "application/json; charset=UTF-8"} }
		).then((resp) => {
			console.log('updateTestRecords --->>', type, resp.data);
		})
		.catch(function (error) {
			console.log('updateTestRecord', error);
		});
	}
	updateInfoStatus(token, data) {
		if (this.state.finalized === true) return;
		axios.post( URL_ROUTE_USER + "/updateInfoStatus?token=" +token, { ...data },
			{ headers: {"Content-type": "application/json; charset=UTF-8"} }
		).then((resp) => {
			console.log('updateInfoStatus --->>', resp.data);
		})
		.catch(function (error) {
			console.log('updateInfoStatus', error);
		});
	}

	pollingStatus(token) {
		let cover = Number(this.state.cover);
		axios.get(URL_ROUTE_USER + "/getAnswer?token=" + token + "&cover=" + cover)
			.then(async (res) => {
				let { pageStatus, flag, answer, uuid } = res.data;
				this.setState({ pageStatus, flag, answer, uuid });
				localStorage.setItem("log_uuid", uuid);
				// console.log({pageStatus, flag, answer})
				// console.log(this.state.q_idx)
				// console.log(this.state.q_idx)
				// console.log(Q_ARR[this.state.q_idx-2])
				// console.log(localStorage.getItem(Q_ARR[this.state.q_idx-2]))
				if (flag == 1) {
					// New answer from mobile
					this.setState({ cover: true });

					if (answer == 9) {
						// Refresh on mobile
						if (this.state.nextTest) {
							// let head =  "Stage " + (this.state.q_idx-1).toString() + " Complete! "
							let head = this.props.t("Stage Complete! ");
							if (this.state.q_idx >= 7) {
								head =
									head +
									this.props.t("Please wear your glasses for the next test.");
							}
							// let subhead = this.props.t("Cover your ");
							let subhead = "Cover your ";
							if (this.state.runCount == 0) {
								subhead = subhead + "RIGHT";
							} else {
								subhead = subhead + "LEFT";
							}
							subhead = this.props.t(subhead + " eye then press 'Next Stage'");
							this.setState({ head, subhead });
							this.setState({ nextTest: false });
						}
					} else if (!this.state.nextTest && pageStatus >= 5) {
						let {
							or,
							va,
							runCount,
							wrongCount,
							changeInt,
							axis_x,
							axis_y,
							nextTest,
							answerCorrect,
						} = handleQuestion(
							pageStatus,
							answer,
							this.state.or,
							this.state.va,
							this.state.runCount,
							this.state.wrongCount,
							this.state.changeInt,
							this.state.axis_x,
							this.state.axis_y
						);
						let q_idx = this.state.q_idx;
						let left = this.state.left;
						console.table({
							q_idx,
							pageStatus,
							answer,
							or,
							va,
							runCount,
							wrongCount,
							changeInt,
							axis_x,
							axis_y,
							left,
							nextTest,
							answerCorrect
						});

						await this.updateTestRecord(token, {
							q_idx,
							pageStatus,
							answer,
							or,
							va,
							runCount,
							wrongCount,
							changeInt,
							axis_x,
							axis_y,
							left,
							nextTest,
							answerCorrect,
							state: this.state
						});

						await this.updateInfoStatus(token, {
							infos: {
								pageStatus,
								changePage: runCount !== this.state.runCount,
								left: (runCount !== this.state.runCount) ? !left : left,
								nextTest,
								q_idx
							}
						});

						if (runCount != this.state.runCount) {
							left = !left;
							// Next Test
							if (nextTest) {
								// Salvar no recoords o resultado da resposta do teste
								const valueQARR = {};
								valueQARR["finalizedAt"] = new Date();

								let axis = 0;
								if (pageStatus == 5) {
									valueQARR["test"] = "test2";
									localStorage.setItem(Q_ARR[q_idx], va);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
									if (this.state.wearing) {
										pageStatus = 7;
										nextTest = false; // End of test with glasses
										this.props.history.push({ pathname: "/thankyou" });
									} else {
										va = localStorage.getItem(Q_ARR[2]) * VA_MULTIPLIER;
										if (va > MAX_AXIS_VA) {
											va = MAX_AXIS_VA;
										}
									}
								} else if (pageStatus == 6) {
									// localStorage.setItem(Q_ARR[q_idx], 175)// HARD CODE RIGHT Axis getAngle(axis_x, axis_y, wrongCount))
									valueQARR["test"] = "test3";
									localStorage.setItem(
										Q_ARR[q_idx],
										getAngle(axis_x, axis_y, wrongCount)
									);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: getAngle(axis_x, axis_y, wrongCount) };
									localStorage.setItem(
										Q_ARR[q_idx] + "_percent",
										(Q3_ORIENTATIONS.length - wrongCount) /
											Q3_ORIENTATIONS.length
									);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: (Q3_ORIENTATIONS.length - wrongCount) / Q3_ORIENTATIONS.length };
									axis_x = axis_y = wrongCount = 0;
									va = INITVA;
									axis = localStorage.getItem(Q_ARR[4]);
								} else if (pageStatus == 7) {
									valueQARR["test"] = "test4";
									localStorage.setItem(Q_ARR[q_idx], va);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: va };

									await this.updateTestRecord(token, valueQARR , "results");

									if (
										!this.state.wearing &&
										localStorage.getItem("HaveSpec") == "yes"
									) {
										// Test corrected
										this.setState({ wearing: true });
										pageStatus = 4;
										or = getRandomInt(4);
										va = INITVA;
									} else {
										this.setState({ finalized: true });
										nextTest = false; // End of test without glasses
										await fetch(URL_ROUTE_USER + "/delete" + window.location.search)
											.then((response) => response.json())
											.then(async () => {
												console.log('chamada no response do delete');
												await this.updateTestRecord(token, valueQARR , "results");
												console.log('fim chamada no response do delete');
												setTimeout(() => {
													this.props.history.push({ pathname: "/thankyou", search: "?token=" + token });	
												}, 500);
												
											});
									}
								}

								await this.updateTestRecord(token, valueQARR , "results");

								if (this.state.finalized === false) {
									console.log('token finalizado', token);
									// Next test stage
									await fetch(
										URL_ROUTE_USER +
											"/updateStatus?token=" +
											token +
											`&pageStatus=${pageStatus + 1}&axis=${axis}`
									) //Send next axis
										.then((response) => response.json())
										.then((data) => {
											this.setState({ pageStatus: data.pageStatus });
										});
									}
								// Next eye
							} else {
								// Quando finaliza um teste cai nessa etapa
								// Fazer a chamada para gravar as informações que foram geradas no final de cada teste aqui.!!!!!!!
								const valueQARR = {};
								nextTest = true;
								if (pageStatus == 5) {
									valueQARR["test"] = "test2";
									localStorage.setItem(Q_ARR[q_idx], va);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
									va = INITVA;
								} else if (pageStatus == 6) {
									valueQARR["test"] = "test3";
									// localStorage.setItem(Q_ARR[q_idx], 90)// HARD CODE LEFT EYE AXIS getAngle(axis_x, axis_y, wrongCount))
									localStorage.setItem(
										Q_ARR[q_idx],
										getAngle(axis_x, axis_y, wrongCount)
									);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: getAngle(axis_x, axis_y, wrongCount) };
									localStorage.setItem(
										Q_ARR[q_idx] + "_percent",
										(Q3_ORIENTATIONS.length - wrongCount) /
											Q3_ORIENTATIONS.length
									);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: (Q3_ORIENTATIONS.length - wrongCount) / Q3_ORIENTATIONS.length };
									axis_x = axis_y = wrongCount = 0;
									va = localStorage.getItem(Q_ARR[3]) * VA_MULTIPLIER;
									if (va > MAX_AXIS_VA) {
										va = MAX_AXIS_VA;
									}
								} else if (pageStatus == 7) {
									valueQARR["test"] = "test4";
									// Refresh right eye axis on mobile
									await fetch(
										URL_ROUTE_USER +
											"/updateStatus?token=" +
											token +
											`&pageStatus=${pageStatus}&axis=${localStorage.getItem(
												Q_ARR[5]
											)}`
									).then((response) => response.json());
									// .then(() => { this.setState({nextTest: true})})
									localStorage.setItem(Q_ARR[q_idx], va);
									valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
									va = INITVA;
								}

								await this.updateTestRecord(token, valueQARR , "results");
							}
							q_idx = q_idx + 1;
						}
						this.setState({
							or,
							va,
							runCount,
							wrongCount,
							changeInt,
							nextTest,
							axis_x,
							axis_y,
							left,
							q_idx,
						});
					}
				} else if (flag == 2) {
					let q_idx = this.state.q_idx;
					localStorage.setItem(Q_ARR[q_idx], getSight(answer));
					const valueQARR = {};

					valueQARR["value"] = { name: Q_ARR[q_idx], value: getSight(answer) };
					await this.updateTestRecord(token, valueQARR , "results");
					this.setState({ q_idx: q_idx + 1, cover: true });
				} else {
					this.setState({ cover: false });
				}

				if (
					pageStatus != 999 &&
					window.location.pathname == ROUTES_PC_STARTED_SESSION
				) {
					setTimeout(() => {
						this.pollingStatus(token);
					}, POLLING_INTERVAL);
				}
			})
			.catch((error) => { 
				console.log('pollingStatus', error);
			});
	}

	// componentWillMount() {
	// }

	componentDidMount() {
		let query = getQuery();
		this.pollingStatus(query.token);
	}

	jump() {
		const query = qs.parse(window.location.search);
		// const searchString = qs.stringify(query);
		this.props.history.push({
			pathname: "/test1B",
			search: "?session=" + query.session,
		});
	}

	render() {
		return (
			<>
				{this.state.pageStatus ==
					PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK && (
					<WaitScreen
						left={this.state.left}
						q_idx={this.state.q_idx}
						pageStatus={this.state.pageStatus}
						head=""
						subhead={`${this.props.t("Take")} ${localStorage.getItem(
							"NumberOfSteps"
						)} ${this.props.t(
							"heel-to-toe steps (10ft) away from your computer screen"
						)}`}
					/>
				)}
				{(this.state.pageStatus == PAGE_STATUS_PLACING_OBJECT ||
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST1A) && (
					<WaitScreen
						left={this.state.left}
						q_idx={this.state.q_idx}
						pageStatus={this.state.pageStatus}
						head={this.props.t("Test in process")}
						subhead={this.props.t("Waiting for action on mobile device...")}
					/>
				)}
				{this.state.pageStatus == PAGE_STATUS_PLACING_TEST1B && <Test1B />}
				{this.state.nextTest &&
					this.state.pageStatus >= PAGE_STATUS_PLACING_TEST2 && (
						<WaitScreen
							left={this.state.left}
							q_idx={this.state.q_idx}
							pageStatus={this.state.pageStatus}
							head={this.state.head}
							subhead={this.state.subhead}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST2 && (
						<Test2
							orientation={getOrientation(this.state.or)}
							size={getSize(this.state.va, parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST3 && (
						<Test3
							orientation={Q3_ORIENTATIONS[this.state.or]}
							size={getSize(INITVA-this.state.scR, 67+parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST4 && (
						<Test4
							orientation={getOrientation(
								this.state.or,
								Number(localStorage.getItem(Q_ARR[this.state.q_idx - 2]))
							)}
							size={getSize(this.state.va, parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
			</>
		); //%NOTE% check if orientation good ,,, this.state.left?this.state.orl:this.state.orr
	}
}
function getCartoon(pageStatus, q_idx) {
	let cartoon_idx;
	if (q_idx == 0) {
		cartoon_idx = pageStatus - 1;
	} else if (q_idx >= 8) {
		cartoon_idx = (q_idx % 2) + 5;
	} else {
		cartoon_idx = (q_idx % 2) + 3;
	}
	// console.log(q_idx, cartoon_idx)
	return cartoon_idx;
}

class WaitScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cartoon_arr: [
				cartoonOne,
				cartoonTwo,
				cartoonBlank,
				cartoonRight,
				cartoonLeft,
				cartoonRightGlass,
				cartoonLeftGlass,
			],
		};
	}
	render() {
		return (
			<>
				<div className="frame">
					<div className="connectionContent">
						<div>
							<img
								src={
									this.state.cartoon_arr[
										getCartoon(this.props.pageStatus, this.props.q_idx)
									]
								}
								alt=""
								style={{ width: "70%" }}
							/>
						</div>
						<div className="connectionContentTitle">
							<h1>{this.props.head}</h1>
						</div>
						<div className="connectionContentText">
							<h2>{this.props.subhead}</h2>
						</div>
						<Spinner
							as="span"
							animation="grow"
							size="m"
							role="status"
							aria-hidden="true"
						/>
					</div>
				</div>
			</>
		);
	}
}

function Test1B(props) {
	const { t } = useTranslation();
	const questionText = t("1C Which one is more clear? (Select on mobile)");
	return (
		<div className="test-instructions-wrapper">
			<div className="frame">
				<h1 style={{ margin: "40px 0" }}>{questionText}</h1>
				<img src={nearFar} alt="" style={{ width: "60%" }} />
			</div>
		</div>
	);
}

function TestHeader(props) {

	const { t } = useTranslation();

	return (
		<div className="test-instructions">
			{!props.wearing ? (
				<h2>
					{t("Glasses OFF")}
				</h2>
			) : (
				<h2>
					{t("Glasses ON")}
				</h2>
			)}
			{(props.left && props.runCount > 0) ? (
				<h2>
					{t("Cover your RIGHT eye")}
				</h2>
			) : (
				<h2>
					{t("Cover your LEFT eye")}
				</h2>
			)}
		</div>
	);
}

class Test2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { sketch: big_c };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
						c={this.props.cover}
					/>
				</div>
			</div>
		);
	}
}

class Test3 extends React.Component {
	// %NOTE% Cover?
	constructor(props) {
		super(props);
		this.state = { sketch: axis };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
					/>
				</div>
			</div>
		);
	}
}

class Test4 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { sketch: big_c };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
						c={this.props.cover}
					/>
				</div>
			</div>
		);
	}
}

export default withTranslation()(withRouter(Page12StartSession));
