import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import Swal from "sweetalert2";

export default function Subscribe({path}) {
	const [state, setState] = useState({});
	const { t, i18n } = useTranslation();

	const pay = () => {
		setState((s) => {
			return {
				...s,
				status: "loading",
			};
		});

		let returnPath = window.location.href;
		if(path) returnPath = path;

		axios
			.post(
				`${process.env.REACT_APP_API}/user/create-payment-session?lng=${i18n.language || "en"}`,
				{
               path: returnPath
            },
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				if (res?.data?.result) {
					window.location.href = res.data.url;
				}
			})
			.catch((e) => {
				let error = "Could not process at this time. Please, try again later.";
				if (e.response.status === 400 && e.response?.data?.error) {
					error = e.response.data.error;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error,
				});
			});
	};

	return (
		<div className="subscription-view-notice">
			<h2>{t("Looks like you are not subscribed yet")}</h2>
			<p>{t("Get started with EyeTesters subscription to view your tests.")}</p>
			{state?.status !== "loading" ? (
				<button className="button" onClick={pay}>
					{t("Subscribe now")}
				</button>
			) : (
				<div style={{ textAlign: "center", width: "100%" }}>
					<Spinner />
				</div>
			)}
		</div>
	);
}
