import React, { useState, useEffect } from "react";
import LoginLogo from "../assets/images/005.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { useTranslation } from "react-i18next";

function nl2br (str, replaceMode, isXhtml) {

	var breakTag = (isXhtml) ? '<br />' : '<br>';
	var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

function Feedback() {
	const { t } = useTranslation();
	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const [validToken, setValidToken] = useState(false);
	const [nameUser, setNameUser] = useState("");

	const check = async () => {
		const token = new URLSearchParams(location.search).get("token");
		setLoading(true);
		try {

			if (token && validToken === false) {
				await axios
					.post(
						`${process.env.REACT_APP_API}/user/feedback-validation`,
						{ token: token},
						{
							withCredentials: true,
						}
					)
					.then(function (res) {
						console.log('res.data', res);
						
							setValidToken(true);
							setNameUser(res.data.name);
							setLoading(false);
					})
					.catch((e) => {
						let error = t("Token is invalid!!!");
						Swal.fire({
							icon: "error",
							title: "Ooopsss!",
							text: error,
						});
						setLoading(false);
					});
			} 
		} catch (error) {
			setState({
				view: "error",
			});
		}
	};

	useEffect(() => {
		check();
	}, []);

	const SendFeedback = () => {
		const token = new URLSearchParams(location.search).get("token");
		if (!state.message)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a message"),
			});

		setLoading(true);

		axios
			.post(`${process.env.REACT_APP_API}/user/send-feedback`, { 
				token,
				message: nl2br(state.message, true, false)
			}, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result) {
					Swal.fire({
						title: t("Feedback sent successfully!"),
						showCancelButton: false,
						confirmButtonText: 'Ok',
						allowOutsideClick: false,
						icon: "success",
					}).then((result) => {
							if (result.isConfirmed) {
								history.push("/home");
							}
					})
					setLoading(false);
				} else {
					setLoading(false);
					let error =
						t("Could not send feedback at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				let error = t("Could not send feedback at this time. Please, try again later.");

				Swal.fire({
					icon: "error",
					title: "Error",
					text: error,
				});
			});
	};

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="Eyetesters" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						<div className="form-inset">
							<h2>{nameUser}</h2>
							<span>{t("What’s your opinion on the test and its results?")}</span>

							<div className="form-group">
								<textarea
										style={{ minHeight: "200px", padding: "10px" }}
										name="message"
										value={state?.message || ""}
										onChange={(e) => {
											let value = e.target.value;
											setState((state) => {
												return {
													...state,
													message: value,
												};
											});
										}}
								></textarea>
							</div>

							<div className="form-options">
								{!loading ? (
									<button onClick={SendFeedback}>{t("Send")}</button>
								) : (
									<Spinner />
								)}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

export default Feedback;
