import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { ReactComponent as Check } from "../assets/images/check.svg";

function EmailValidation() {

	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const [state, setState] = useState({
		view: "loading",
	});
	const [validToken, setValidToken] = useState(false);

	const check = async () => {
		const token_validation = new URLSearchParams(location.search).get("token_validation");
		try {

			if (token_validation && validToken === false) {
				await axios
					.post(
						`${process.env.REACT_APP_API}/user/email-validation`,
						{ token: token_validation },
						{
							withCredentials: true,
						}
					)
					.then(function (res) {
							setValidToken(true);
							setState({
								view: "success",
							});
					})
					.catch((e) => {
						setState({
							view: "error",
						});
						// let error = t("Token is invalid!!!");
						// Swal.fire({
						// 	icon: "error",
						// 	title: "Ooopsss!",
						// 	text: error,
						// });
					});
			} 
		} catch (error) {
			setState({
				view: "error",
			});
		}
	};

	useEffect(() => {
		check();
	}, []);

	return (
		<>
			{state.view === "loading" && (
				<main className="loading">
					<Spinner />
				</main>
			)}

			{state.view === "success" && (
				<main className="loading">
					<Check />
					<div className="loading-options">
						<h2>{t("Email validation completed successfully")}</h2>
						<button
							onClick={() => {
								// history.push(`/home`);
								window.location.href = "/home";
							}}
						>
							{t("Home")}
						</button>
					</div>
				</main>
			)}

			{state.view === "error" && (
				<main className="payment-not-ready">
					<div className="inset">
						<h1 style={{fontSize: "4rem"}}>{t("Token is invalid!!!")}</h1>
						<span style={{fontSize: "20px"}}>
							{t("Unable to verify your email. Please go to “My Profile” and request a new verification.​")}
						</span>
						<button
							onClick={() => {
								history.push(`/profile`);
							}}
						>
							{t("My Profile")}
						</button>
					</div>
				</main>
			)}
		</>
	);
}

export default EmailValidation;
