// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./styles.css";
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

export default function Page7shoesize() {
	let history = useHistory();
	const [size, setSize] = useState("");
	const [ShoeType, setShoeType] = useState("");
	const [ShoeSex, setShoeSex] = useState("");
  const { t, i18n } = useTranslation();

	const qsName = "shoesize";
	const PATH = "/wearing";
	const questionText = t("What is your shoe size?");

	const buttonAction = (size, ShoeType, ShoeSex) => {
		if (ShoeType == "") {
			alert(t("Please Select Region"));
		} else if (ShoeSex == "") {
			alert(t("Please select shoe gender"));
		} else {
			size = Math.round(size);
			let msg = "";
			let trigger = false;

			if (ShoeType == "US" && ShoeSex == "M") {
				if (size < 6 || size > 16) {
					msg =
						t("size is out of range of american shoes, it must be between size 6 and 16");
					trigger = true;
				}
			} else if (ShoeType == "UK" && ShoeSex == "M") {
				//UK SHOE CONVERSIONS
				if (size < 5.5 || size > 15.5) {
					msg =
						t("size is out of range of UK shoes, it must be between size 5.5 and 15.5");
					trigger = true;
				}
			} else if (ShoeType == "Europe" && ShoeSex == "M") {
				//European SHOE CONVERSIONS
				if (size < 39 || size > 49) {
					msg =
						t("size is out of range of European shoes, it must be between size 39 and 49");
					trigger = true;
				}
			} else if (ShoeType == "US" && ShoeSex == "F") {
				//US AND CANADIAN SHOE CONVERSIONS
				if (size < 4 || size > 12) {
					msg =
						t("size is out of range of american shoes, it must be between size 4 and 12");
					trigger = true;
				}
			} else if (ShoeType == "UK" && ShoeSex == "F") {
				//UK SHOE CONVERSIONS
				if (size < 2 || size > 10) {
					msg =
						t("size is out of range of UK shoes, it must be between size 2 and 10");
					trigger = true;
				}
			} else if (ShoeType == "Europe" && ShoeSex == "F") {
				//European SHOE CONVERSIONS
				if (size < 34 || size > 42.5) {
					msg =
						t("size is out of range of European shoes, it must be between size 34 and 42.5");
					trigger = true;
				}
			}

			if (trigger == false) {
				CaculateShoeSize(size, ShoeType, ShoeSex);
				history.push({
					pathname: PATH,
				});
			}

			if (trigger == true) {
				alert(msg);
			}
		}
	};

	return (
		<TestWrapper image={`images/test/008.jpg`}>
			<h2>{questionText}</h2>
			<div className="answerAreaRyan4, mt-3">
				<Form.Control
					className="mt-3"
					as="select"
					value={ShoeType}
					onChange={(v) => {
						setShoeType(v.target.value);
					}}
					size={"lg"}
				>
					<option value="0"> {t("Select Region")} </option>
					<option value="US">{t("US and Canada")}</option>
					<option value="UK">{t("UK")}</option>
					<option value="Europe">{t("Europe")}</option>
				</Form.Control>

				<Form.Control
					className="mt-3"
					as="select"
					value={ShoeSex}
					onChange={(v) => {
						setShoeSex(v.target.value);
					}}
					size={"lg"}
				>
					<option value=""> {t("Select Gender for Shoe Measurements")} </option>
					<option value="M">{t("Male")}</option>
					<option value="F">{t("Female")}</option>
				</Form.Control>

				<Form
					className="mt-3"
					onSubmit={(v) => {
						buttonAction(qsName, size);
					}}
					onKeyPress={(e) => {
						e.key === "Enter" && e.preventDefault();
					}}
				>
					<Form.Group>
						<Form.Control
							type="number"
							placeholder={t("Enter your shoe size here")}
							size={"lg"}
							onChange={(v) => {
								setSize(v.target.value);
							}}
						/>
					</Form.Group>
				</Form>
				<div className="nav-view">
					<div
						className="nav-button"
						variant="primary"
						type="submit"
						onClick={() => buttonAction(size, ShoeType, ShoeSex)}
					>
						{t("Continue")}
					</div>
				</div>
			</div>
		</TestWrapper>
	);
}

function CaculateShoeSize(size, ShoeType, ShoeSex) {
	// alert("Shoe Size = " +size+ " Shoe Type = " + ShoeType+" Shoe Sex= " + ShoeSex)
	//need a way to round to the nearest 0.5 if ShoeType is UK or US measurements
	//need a way to round to the nearest 1.0 if ShoeType is European

	if (ShoeSex == "M") {
		if (ShoeType == "US") {
			//US AND CANADIAN SHOE CONVERSIONS
			if (size == 6) {
				size = 23.5;
			}
			if (size == 6.5) {
				size = 24.1;
			}
			if (size == 7) {
				size = 24.4;
			}
			if (size == 7.5) {
				size = 24.8;
			}
			if (size == 8) {
				size = 25.4;
			}
			if (size == 8.5) {
				size = 25.7;
			}
			if (size == 9) {
				size = 26;
			}
			if (size == 9.5) {
				size = 26.7;
			}
			if (size == 10) {
				size = 27;
			}
			if (size == 10.5) {
				size = 27.3;
			}
			if (size == 11) {
				size = 27.9;
			}
			if (size == 11.5) {
				size = 28.3;
			}
			if (size == 12) {
				size = 28.6;
			}
			if (size == 13) {
				size = 29.4;
			}
			if (size == 14) {
				size = 30.2;
			}
			if (size == 15) {
				size = 31;
			}
			if (size == 16) {
				size = 31.8;
			}
		}
		if (ShoeType == "UK") {
			//UK SHOE CONVERSIONS
			if (size == 5.5) {
				size = 23.5;
			}
			if (size == 6) {
				size = 24.1;
			}
			if (size == 6.5) {
				size = 24.4;
			}
			if (size == 7) {
				size = 24.8;
			}
			if (size == 7.5) {
				size = 25.4;
			}
			if (size == 8) {
				size = 25.7;
			}
			if (size == 8.5) {
				size = 26;
			}
			if (size == 9) {
				size = 26.7;
			}
			if (size == 9.2) {
				size = 27;
			}
			if (size == 10) {
				size = 27.3;
			}
			if (size == 10.5) {
				size = 27.9;
			}
			if (size == 11) {
				size = 28.3;
			}
			if (size == 11.5) {
				size = 28.6;
			}
			if (size == 12.5) {
				size = 29.4;
			}
			if (size == 13.5) {
				size = 30.2;
			}
			if (size == 14.5) {
				size = 31;
			}
			if (size == 15.5) {
				size = 31.8;
			}
		}
		if (ShoeType == "Europe") {
			//European SHOE CONVERSIONS
			if (size == 39) {
				size = 23.5;
			}
			if (size == 39.5) {
				size = 24.1;
			}
			if (size == 40) {
				size = 24.4;
			}
			if (size == 40.5) {
				size = 24.8;
			}
			if (size == 41) {
				size = 25.4;
			}
			if (size == 41.5) {
				size = 25.7;
			}
			if (size == 42) {
				size = 26;
			}
			if (size == 42.5) {
				size = 26.7;
			}
			if (size == 43) {
				size = 27;
			}
			if (size == 43.5) {
				size = 27.3;
			}
			if (size == 44) {
				size = 27.9;
			}
			if (size == 44.5) {
				size = 28.3;
			}
			if (size == 45) {
				size = 28.6;
			}
			if (size == 46) {
				size = 29.4;
			}
			if (size == 47) {
				size = 30.2;
			}
			if (size == 48) {
				size = 31;
			}
			if (size == 49) {
				size = 31.8;
			}
		}
	}
	if (ShoeSex == "F") {
		if (ShoeType == "US") {
			//US AND CANADIAN SHOE CONVERSIONS
			if (size == 4) {
				size = 20.8;
			}
			if (size == 4.5) {
				size = 21.3;
			}
			if (size == 5) {
				size = 21.6;
			}
			if (size == 5.5) {
				size = 22.2;
			}
			if (size == 6) {
				size = 22.5;
			}
			if (size == 6.5) {
				size = 23;
			}
			if (size == 7) {
				size = 23.5;
			}
			if (size == 7.5) {
				size = 23.8;
			}
			if (size == 8) {
				size = 24.1;
			}
			if (size == 8.5) {
				size = 24.6;
			}
			if (size == 9) {
				size = 25.1;
			}
			if (size == 9.5) {
				size = 25.4;
			}
			if (size == 10) {
				size = 25.9;
			}
			if (size == 10.5) {
				size = 26.2;
			}
			if (size == 11) {
				size = 26.7;
			}
			if (size == 11.5) {
				size = 27.1;
			}
			if (size == 12) {
				size = 27.6;
			}
		}
		if (ShoeType == "UK") {
			//UK SHOE CONVERSIONS
			if (size == 2) {
				size = 20.8;
			}
			if (size == 2.5) {
				size = 21.3;
			}
			if (size == 3) {
				size = 21.6;
			}
			if (size == 3.5) {
				size = 22.2;
			}
			if (size == 4) {
				size = 22.5;
			}
			if (size == 4.5) {
				size = 23;
			}
			if (size == 5) {
				size = 23.5;
			}
			if (size == 5.5) {
				size = 23.8;
			}
			if (size == 6) {
				size = 24.1;
			}
			if (size == 6.5) {
				size = 24.6;
			}
			if (size == 7) {
				size = 25.1;
			}
			if (size == 7.5) {
				size = 25.4;
			}
			if (size == 8) {
				size = 25.9;
			}
			if (size == 8.5) {
				size = 26.2;
			}
			if (size == 9) {
				size = 26.7;
			}
			if (size == 9.5) {
				size = 27.1;
			}
			if (size == 10) {
				size = 27.6;
			}
		}
		if (ShoeType == "Europe") {
			//European SHOE CONVERSIONS
			if (size == 34) {
				size = 20.8;
			}
			if (size == 35) {
				size = 21.3;
			}
			if (size == 35.5) {
				size = 21.6;
			}
			if (size == 36) {
				size = 22.2;
			}
			if (size == 36.5) {
				size = 22.5;
			}
			if (size == 37) {
				size = 23;
			}
			if (size == 37.5) {
				size = 23.5;
			}
			if (size == 38) {
				size = 23.8;
			}
			if (size == 38.5) {
				size = 24.1;
			}
			if (size == 39) {
				size = 24.6;
			}
			if (size == 39.5) {
				size = 25.1;
			}
			if (size == 40) {
				size = 25.4;
			}
			if (size == 40.5) {
				size = 25.9;
			}
			if (size == 41) {
				size = 26.2;
			}
			if (size == 41.5) {
				size = 26.7;
			}
			if (size == 42) {
				size = 27.1;
			}
			if (size == 42.5) {
				size = 27.6;
			}
		}
	}

	//Use new ShoeSize (size) which is now in cm to figure out the number of steps the user needs to take
	//304.8cm = exactly 10ft. By taking 304.8cm/ShoeSize(cm) and ROUNDING DOWN we get a whole number of steps for the pt to take from the screen

	let steps = Math.round(304.8 / size);

	// alert("take the following number of steps from your screen:" + steps)

	localStorage.setItem("NumberOfSteps", steps);
}

// page: shoe size
