// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import qs from "query-string";
import { QRCode } from "react-qr-svg";
import { Button, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from "react-i18next";
import "./styles.css";
import AudioGuide from '../components/AudioGuide'

function MPagePlaceObject(props) {

     const { t, i18n } = useTranslation();

	useEffect(() => {
		fetch(
			URL_ROUTE_USER +
				"/updateStatus" +
				window.location.search +
				"&pageStatus=2"
		)
			.then((response) => response.json())
			.then((data) => {
				console.log("updated");
				console.log(data);
			});

		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const query = qs.parse(window.location.search);
	const Button1 = () => {
		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/mtest1A",
			search: searchString,
		});
		props.history.push({
			pathname: "/mtest1A",
			search: searchString,
		});
	};
	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("Place an object at your toes to mark your spot")}</h1>
					<h2>
						{t("Confirm that you are now 10 feet from your computer screen with your mobile device")}
					</h2>
					<div className="nav-view">
						<div className="nav-button" onClick={Button1}>
							{t("Confirm")}
						</div>
					</div>
					<AudioGuide 
						lang={query.lang}
						sound="mpage13"
					/>
				</div>
			</div>
		</>
	);
}

export default withRouter(MPagePlaceObject);
