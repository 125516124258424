import React from "react";
import Header from "../components/Header";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

function HomePage() {

	const { t, i18n } = useTranslation();

	return (
		<>
			<Header />

			<div className="content">
				<main className="page-view">
					<h1>{t("Privacy Policy")}</h1>

					<div className="text-content">
						<div>
							
							<div>
								<div>
									<p>
										This Privacy Policy is intended to inform you of what
										personal data we,{" "}
										EyeTesters.com
										&nbsp;collect from you when you visit our website or use our
										services. It explains why we collect this data and how we
										handle it to protect your privacy. Your personal data
										includes information related to your name and contact
										details, such as your home address, telephone number, and
										email address.<u></u>
										<u></u>
									</p>
									<p>
										We encourage you to read this Privacy Policy carefully to
										understand how we process your personal data and ensure its
										protection. If you have any questions about our use of your
										personal data, please contact our Privacy Office at:
										<a
											href="https://hd2olens.com/privacy-policy/service@hd2olens.com"
											target="_blank"
										>
											service@eyetesters.com
										</a>
										<u></u>
										<u></u>
									</p>
									<h4 >
										<u></u>
										<span>
											1.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
												Personal Data We Collect
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
										Data we collect when you visit our website:<u></u>
										<u></u>
									</p>
									<p>
										When you access our website, certain data is automatically
										collected and stored in our server logs and cookies. Cookies
										are small text files stored by a web server in your browser
										software, which are then sent to the server when your
										browser connects.<u></u>
										<u></u>
									</p>
									<p>
										This data does not directly identify you but includes
										information about your browsing history on our website. It
										may include the pages you accessed, the date and time of
										your request and referral URL, your search queries on the
										site, and information about your device, such as its
										hardware model, operating system version, unique device
										identifier, IP address, hardware settings, browser type, and
										browser language.<u></u>
										<u></u>
									</p>
									<p>
										While providing your data is not mandatory for visiting our
										website, please keep in mind that refusing cookies may
										disrupt your navigation and prevent you from accessing
										certain pages or services. You can configure your web
										browser to avoid cookies.<u></u>
										<u></u>
									</p>
									<p>
										Data we collect when you sign up or order products:<u></u>
										<u></u>
									</p>
									<p>
										Certain services on our website require registration or
										sign-up. We ask you to provide complete and accurate
										personal data to access these services. It is essential to
										inform us of any updates to your data. Failure to provide
										complete and accurate data or to update us may affect our
										ability to provide the requested services.<u></u>
										<u></u>
									</p>
									<p>
										We collect the following categories of personal data:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Identification data: Your name, telephone number, email
											address, customer ID, prescription, and similar
											information.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Financial data: Details of your payment method, such as
											credit card information. Please note that we do not store
											the details of any card after the first order has been
											processed.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											IT data: Information related to your website browsings,
											such as the pages you accessed, date and time of requests,
											referral URLs, search queries, and details about your
											device.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											2.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Use of Personal Data<u></u>
										<u></u>
									</h4>
									<p>
										We use your personal data only within the limits that are
										set by the laws and regulations. In some cases, we may
										process your personal data based on legal requirements. We
										do not make automated decisions solely based on automatic
										processing that may affect you legally or significantly.
										<u></u>
										<u></u>
									</p>
									<p>
										We use your personal data for the following purposes:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Identification data: To provide you with services as per
											our contractual obligations.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Financial data: To process sales, returns, or replacement
											orders.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											IT data: To track analytics and improve the efficiency of
											our services.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											3.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
												Data Retention
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
										We retain your personal data for the following periods:
										<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Identification data: 7 years<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Financial data: 10 years<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											IT data: 7 years<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											4.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
												Sharing of Personal Data
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
										We may share your personal data with companies outside of{" "}
										EyeTesters.com
										for specific purposes:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Identification data: Yes, limited strictly to SMS (name
											and telephone number).<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Financial data: No.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											IT data: Yes.<u></u>
											<u></u>
										</li>
									</ul>
									<ol start="5" type="1">
										<li className="MsoNormal">
											<strong>
												<span>
													Personal Data Protection
												</span>
											</strong>
											<u></u>
											<u></u>
										</li>
									</ol>
									<p>
										We take the protection of your personal data seriously and
										have implemented measures to ensure its security and
										confidentiality. These measures include:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Implementation and maintenance of sophisticated technical
											measures to safeguard the recording and processing of your
											personal data with complete confidentiality and security.
											<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Implementation and maintenance of appropriate access
											restrictions to your personal data, as well as monitoring
											the access, use, and transfer of such data.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											All employees who have access to your personal data are
											required to enter into nondisclosure or similar
											agreements, ensuring their compliance with our data
											privacy and confidentiality requirements.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											We require any business partners and third-party service
											providers with whom we share your personal data to comply
											with applicable data privacy and confidentiality
											requirements.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Regular data privacy training for our employees and third
											parties who have access to personal data.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											6.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Your Rights<u></u>
										<u></u>
									</h4>
									<p>
										Under applicable data protection laws and regulations, you
										have the following rights:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Right of access, rectification, and/or erasure of your
											personal data.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Right to restrict or object to the processing of your
											personal data.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Right to inform us that you do not wish to receive
											marketing information.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											In certain circumstances, the right to request the
											transfer of certain personal data to you or a third party.
											<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											If our processing of your personal data is based on your
											consent, the right to withdraw your consent without
											affecting the lawfulness of our processing based on your
											consent before its withdrawal.<u></u>
											<u></u>
										</li>
									</ul>
									<p>
										We are committed to enabling you to exercise your rights. To
										do so, please contact us using the contact details provided
										at the beginning of this Privacy Policy. When submitting a
										request, please include the following information to
										facilitate the processing of your request:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
											Your name and surname, along with a photocopy of your
											identity card.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Specify the rights you wish to exercise.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
											Date of the application and your signature (if sent by
											postal mail).<u></u>
											<u></u>
										</li>
									</ul>
									<p>
										If you are not satisfied with our response or handling of
										your request, you have the right to lodge a complaint with a
										data protection authority.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											7.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Service-related Announcements and Newsletters<u></u>
										<u></u>
									</h4>
									<p>
										We may occasionally send you service-related announcements
										when necessary. For example, if our service is temporarily
										suspended for maintenance, we may send you an email. These
										communications are not promotional in nature, and you cannot
										opt out of receiving them. However, you may choose to
										deactivate your account if you do not wish to receive such
										announcements.<u></u>
										<u></u>
									</p>
									<p>
										If you subscribe to our email newsletter(s), we will use
										your name and email address to send you the newsletters. We
										provide an option to unsubscribe from these newsletters to
										respect your privacy. Instructions for unsubscribing are
										included in each newsletter.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											8.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Opt Out<u></u>
										<u></u>
									</h4>
									<p>
										We offer you the opportunity to opt out of using your
										personal information for certain purposes. For example, if
										you make a purchase but do not wish to receive additional
										promotional emails from us, you can opt out by following the
										unsubscribe link provided in each email communication.
										<u></u>
										<u></u>
									</p>
									<p>
										If you no longer wish to receive our newsletters and
										promotional communications, you can opt out by following the
										instructions included in each newsletter.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											9.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Opt In<u></u>
										<u></u>
									</h4>
									<p>
										By accepting the terms and conditions, you allow us to share
										your data strictly with companies within EyeTesters.com .
										You also give your express, specific, and informed consent
										to receive promotional emails regarding optical products and
										glasses from these companies. Each email you receive from us
										or other companies within our group will include an
										‘unsubscribe’ link.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											10.
											<span>&nbsp;</span>
										</span>
										<u></u>Removing Display/Advertisements from Your Device
										<u></u>
										<u></u>
									</h4>
									<h5>Option A:</h5>
									<p>
										Visit the{" "}
										<a
											href="https://optout.networkadvertising.org/?c=1#!%2F"
											target="_blank"
										>
											NAI website
										</a>
										, which will scan your computer or device for any
										third-party display or advertisements. The website will
										provide a list of such ads, and you can select the
										checkboxes next to the ads you want to remove. This ensures
										that advertisements will not appear on your computer or
										device. Please note that the NAI website is not associated
										with EyeTesters.com
										<u></u>
										<u></u>
									</p>
									<h5>Option B:</h5>
									<p>
										To remove third-party display advertisements from your
										device, you can manually access the cache and cookies of
										your browser and clear them. Please follow the instructions
										below based on your browser:<u></u>
										<u></u>
									</p>
									<h5>	How to clear cookies and cache in Firefox:</h5>
									<ul type="disc">
										<ol start="1" type="1">
											<li className="MsoNormal">
												Open Firefox and click on the menu button (three
												horizontal lines) in the top-right corner.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Select “Options” and then click on “Privacy &amp;
												Security” in the left sidebar.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Under the “Cookies and Site Data” section, click on
												“Clear Data.”<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Ensure that both “Cookies and Site Data” and “Cached Web
												Content” options are selected.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Click on “Clear” to remove the cookies and cache from
												Firefox.<u></u>
												<u></u>
											</li>
										</ol>
									</ul>
									<h5>How to clear cookies and cache in Chrome:</h5>
									<ul type="disc">
										<ol start="1" type="1">
											<li className="MsoNormal">
												Open Chrome and click on the menu button (three vertical
												dots) in the top-right corner.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Select “Settings” and then click on “Privacy and
												Security” in the left sidebar.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Click on “Clear browsing data.”<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												In the popup window, select “Cookies and other site
												data” and “Cached images and files.”<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Choose the time range for which you want to clear the
												data, or select “All time” to remove all cookies and
												cache.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Click on “Clear data” to clear the cookies and cache
												from Chrome.<u></u>
												<u></u>
											</li>
										</ol>
									</ul>
									<h5>How to clear cookies and cache in Internet Explorer:</h5>
									<ul type="disc">
										<ol start="1" type="1">
											<li className="MsoNormal">
												Open Internet Explorer and click on the gear icon
												(Tools) in the top-right corner.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Select “Internet Options” from the dropdown menu.<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												In the “General” tab, under the “Browsing history”
												section, click on “Delete.”<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Ensure that “Cookies and website data” and “Temporary
												Internet files and website files” options are selected.
												<u></u>
												<u></u>
											</li>
											<li className="MsoNormal">
												Click on “Delete” to remove the cookies and cache from
												Internet Explorer.<u></u>
												<u></u>
											</li>
										</ol>
									</ul>
									<p>
										Please note that clearing cookies and cache may sign you out
										of websites and remove your saved preferences. It will also
										remove other browsing history and data stored by your
										browser.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											11.
											<span>&nbsp;</span>
										</span>
										<u></u>Changes to This Privacy Notice<u></u>
										<u></u>
									</h4>
									<p>
										We regularly review our compliance with this Privacy Policy
										and make updates to ensure compliance with new laws and
										regulations related to data protection. Any changes to this
										Privacy Policy will be posted on our website, and we will
										not reduce your rights under this Privacy Policy without
										obtaining your explicit consent.<u></u>
										<u></u>
									</p>
									<p>
										We encourage you to check our Privacy Policy periodically
										for any updates or changes.<u></u>
										<u></u>
									</p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
