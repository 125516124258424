import React, { useRef, useState } from "react";
import LoginLogo from "../assets/images/login-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { HashLink } from "react-router-hash-link";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { useTranslation } from "react-i18next";

function ForgottenPassword(props) {

	const { t } = useTranslation();

	const captchaRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({});

	const history = useHistory();

	const RECAPTCHA_SECRET=process.env.REACT_APP_RECAPTCHA_SECRET;

	const forgotten = () => {
		if (!state.email)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid e-mail"),
			});
		
		setLoading(true);

		axios
			.post(`${process.env.REACT_APP_API}/user/forgotten`, { 
				...state,
				text_reset_first: t("text_reset_first"),
				text_reset_second: t("text_reset_second"),
				text_reset_footer: t("text_reset_footer"),
				text_reset_subject: t("text_reset_subject"),
				text_reset_button: t("text_reset_button"), 
			}, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result) {
					setLoading(false);
					Swal.fire({
						title: t("We have sent you an email with instructions to reset your password."),
						showCancelButton: false,
						confirmButtonText: 'Ok',
						allowOutsideClick: false,
						icon: "success",
				}).then((result) => {
						if (result.isConfirmed) {
							history.push("/home");
						}
				})

				} else {
					setLoading(false);
					let error = t("Could not sent you an email at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: t("Sorry"),
						text: error,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				let error = t("Could not sent you an email at this time. Please, try again later.");
				if (e.response.status === 400 && e.response?.data?.error) {
					error = t(e.response.data.error);
				}
				Swal.fire({
					icon: "error",
					title: t("Sorry"),
					text: error,
				});
			});
			captchaRef.current.reset();
	};

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						<div className="form-inset">
							<h2>{t("Reset Password")}</h2>
							<span>{t("Don't worry we will help you.")}</span>

							<div className="form-group">
								<label>{t("Your e-mail")}</label>
								<input
									type="text"
									value={state?.email || null}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												email: value,
											};
										});
									}}
								/>
							</div>

							<div className="recatpcha">
								<ReCAPTCHA
									sitekey={RECAPTCHA_SECRET}
									ref={captchaRef}
									onChange={(token) => {
										setState((state) => {
											return {
												...state,
												token,
											};
										});
									}}
								/>
							</div>

							<div className="form-options">
								{!loading ? (
									<button onClick={forgotten}>{t("Continue")}</button>
								) : (
									<Spinner />
								)}
							</div>


							<div className="not-registered">
								{t("Have a password?")} <HashLink to="/login">{t("Sign in")}</HashLink>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

export default ForgottenPassword;
